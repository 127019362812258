import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import {
  Button,
  Flex,
  Text,
  useColorModeValue,
  Icon,
  Spacer,
  Spinner,
  Select
} from '@chakra-ui/react';
import { MdCircle } from 'react-icons/md';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import FakturaTable from 'components/tables/FakturaTable';
import useLoaderStore from 'contexts/globalStore';
import { useProjectStore } from 'contexts/globalStoreProjects';
import { tableStorage } from 'contexts/tableStorage';
import useUserDataRolesStore from 'contexts/authStore';
import { checkIfUserFakturaAdmin, checkIfUserProjectCreator } from 'utils/roleHelpers';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE_DEFAULT } from 'variables/pagination';
import { Company, useCompanyStore } from 'contexts/globalStoreCompanies';

type RowObj = {
  id: number;
  title: string;
  description: string;
  companyName: string;
  action: string;
};
export const SearchTableProjects = memo(() => {
  const textColor = useColorModeValue('navy.700', 'white');
  const tableName = useMemo(() => 'projects', []);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const fetchProjects = useProjectStore(
    (state: any) => state.fetchProjects,
  );
  const { fakturaProjects } = useProjectStore(
    (state: any) => ({ fakturaProjects: state.projects }),
  );
  const { projectsMeta } = useProjectStore(
    (state: any) => ({ projectsMeta: state.projectsMeta })
  );
  // const { fetchProjectUsers } = useProjectStore(
  //   (state: any) => ({ fetchProjectUsers: state.fetchProjectUsers }),
  // ); // May be deleted if no bugs related to roles

  const companies = useCompanyStore((state) => state.companies);
  const fetchCompanies = useCompanyStore((state) => state.fetchCompanies);

  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);

  const userRoles = useUserDataRolesStore((state) => state.userRoles);
  const isUserProjectCreator = useMemo(() => checkIfUserProjectCreator(userRoles), [userRoles]);
  const isUserFakturaAdmin = useMemo(() => checkIfUserFakturaAdmin(userRoles), [userRoles]);
  const userProjectsIdsByRole = useUserDataRolesStore((state) => state.userProjectsIdsByRole);

  const storedFilters = useMemo(() => tableStorage.fetch(tableName), [tableName]);

  const [globalFilter, setGlobalFilter] = useState(storedFilters.globalFilter || '');
  const [companyId, setCompanyId] = useState<number>(storedFilters.companyId || null);
  const [pagination, setPagination] = useState<PaginationState>({ pageSize: storedFilters.pageSize || PAGE_SIZE_DEFAULT, pageIndex: PAGE_INDEX_DEFAULT });
  const [sorting, setSorting] = useState<SortingState>(storedFilters.sorting || []);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      setData(fakturaProjects);
    }
    return () => {
      isCurrent = false;
    };
  }, [fakturaProjects]);

  const navigate = useNavigate();

  const refreshData = useCallback(async () => {
    try {
      setLoadingState(true);
      await fetchProjects(
        pagination.pageIndex + 1,
        pagination.pageSize,
        globalFilter,
        companyId,
        sorting[0]?.id || null,
        sorting[0]?.desc ? 'desc' : 'asc'
      );
      // await fetchProjectUsers(); // May be deleted if no bugs related to roles
    } catch (error) {
      console.error('Error fetching Faktura projects:', error);
    } finally {
      setLoadingState(false);
    }
  }, [setLoadingState, fetchProjects, pagination.pageIndex, pagination.pageSize, globalFilter, companyId, sorting]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const columnHelper = createColumnHelper<RowObj>();

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="projects-table-header-id"
        >
          id
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="md" fontWeight="500"
          data-test-id={`projects-table-cell-id-${info.getValue()}`}
        >
          #{info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('title', {
      id: 'title',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="projects-table-header-title"
        >
          NAME
        </Text>
      ),
      cell: (info: any) => {
        const isActive = info.row.original.isActive;
        const id = info.row.original.id;

        return (
          <Flex alignItems={'center'} data-test-id={`projects-table-cell-title-${id}`}>
            <Text color={textColor} fontSize="md" fontWeight="500">
              {info.getValue()}
            </Text>
            <Icon
              ml="5px"
              color={isActive ? 'green.500' : 'gray.500'}
              as={MdCircle}
              w="10px"
              h={'10px'} />
          </Flex>
        );
      },
    }),
    columnHelper.accessor('description', {
      id: 'description',
      enableSorting: false,
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="projects-table-header-description"
        >
          {t('description', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id
        return (
          <Text color={textColor} fontSize="md" fontWeight="500"
            data-test-id={`projects-table-cell-description-${id}`}
          >
            {info.getValue()}
          </Text>
        );
      },
    }),
    columnHelper.accessor('companyName', {
      id: 'companyName',
      enableSorting: false,
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="projects-table-header-description"
        >
          {t('company', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id
        return (
          <Text color={textColor} fontSize="md" fontWeight="500"
            data-test-id={`projects-table-cell-company-${id}`}
          >
            {info.getValue()}
          </Text>
        );
      },
    }),
    columnHelper.accessor('action', {
      id: 'action',
      enableSorting: false,
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          paddingEnd={4}
          data-test-id="projects-table-header-actions"
        >
          {t('actions', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id
        const isUserProjectAdmin = userProjectsIdsByRole.techAdmin.includes(id);
        const isUserProjectFinancialAdmin = userProjectsIdsByRole.finAdmin.includes(id);

        return (<>{isUserProjectFinancialAdmin || isUserProjectAdmin || isUserFakturaAdmin ? (
          <Button
            cursor="pointer"
            variant="brand"
            id={id.toString()}
            data-test-id={`projects-table-cell-action-${id}`}
            onClick={() => navigate(`/projects/edit/${id}`)}
          >
            {t('view', { ns: ['actions'] }) + '/' + t('edit', { ns: ['actions'] })}
          </Button>
        ) : null}
        </>)
      },
    }),
  ]

  const [data, setData] = useState<RowObj[]>(() => []);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting
    },
    pageCount: projectsMeta.totalPages,
    rowCount: projectsMeta.totalItems,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  // Old approach for fetching filters from local storage.
  // If works good, we should repeat the same in other tables.
  // useEffect(() => { 
  //   const {
  //     globalFilter: newGlobalFilter,
  //     companyId: newCompanyId,
  //     pageSize: newPageSize,
  //     sorting: newSorting
  //   } = tableStorage.fetch(tableName)

  //   if (newGlobalFilter) setGlobalFilter(newGlobalFilter);
  //   if (newCompanyId) setCompanyId(newCompanyId);
  //   if (newPageSize) setPagination({ ...pagination, pageSize: newPageSize });
  //   if (newSorting) setSorting(newSorting)
  //   // Don't add 'pagination' below to avoid infinite loop
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tableName])

  useEffect(() => {
    tableStorage.save(tableName, { globalFilter, companyId, pageSize: pagination.pageSize, sorting })
    refreshData();
  }, [companyId, globalFilter, pagination.pageSize, refreshData, sorting, tableName])

  return (
    <Flex
      direction="column"
      w="100%"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        align={{ sm: 'flex-start', lg: 'flex-start' }}
        justify={{ sm: 'flex-start', lg: 'flex-start' }}
        direction={{ sm: 'column', lg: 'row' }}
        w="100%"
        px="22px"
        mb="36px"
        minWidth="max-content"
        alignItems="center"
        gap="4"
      >
        <DebouncedInput
          value={globalFilter ?? ''}
          className="font-lg border-block border p-2 shadow"
          placeholder={t('search', { ns: ['labels'] })}
          data-test-id="projects-table-filter-search"
          onChange={(value) => setGlobalFilter(String(value))}
        />

        <Select
          placeholder={t('allCompanies', { ns: ['labels'] })}
          w="50"
          value={companyId || ''}
          data-test-id="projects-table-filter-company"
          onChange={e => setCompanyId(e.target.value ? Number(e.target.value) : null)}
        >
          {[...companies]?.sort(
            (a, b) => a.name.localeCompare(b.name)
          ).map((company: Company) => (
            <option key={company.id} value={company.id}>
              {company.name}
            </option>
          ))}
        </Select>

        <Spacer />
        {loadingState ? (
          <Spinner />
        ) : isUserProjectCreator ? (
          <Button
            variant="brand"
            data-test-id="projects-table-create-button"
            onClick={() => navigate('/projects/create') as any}
          >
            {t('create', { ns: ['actions'] })}
          </Button>
        ) : null}
      </Flex>
      <FakturaTable
        table={table}
        loadingState={loadingState}
        pagination={pagination}
        setPagination={setPagination}
        data-test-id="projects-table"
      />
    </Flex>
  );
})

// A debounced input react component
//TODO DRY
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <SearchBar
      {...props}
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      h="44px"
      w={{ lg: '390px' }}
      borderRadius="16px"
    />
  );
}

export default SearchTableProjects;
