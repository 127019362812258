export enum AdminRoles {
  FAKTURA_ADMIN = 'FAKTURA_ADMIN',
  POSTOS_ADMIN = 'POSTOS_ADMIN',
  FAKTURA_PROJECT_CREATOR = 'FAKTURA_PROJECT_CREATOR',
}

export enum CreatorRoles {
  FAKTURA_PROJECT_CREATOR = 'FAKTURA_PROJECT_CREATOR',
  COMPANY_CREATOR = 'FAKTURA_COMPANY_CREATOR',
  COSTCENTER_CREATOR = 'FAKTURA_COSTCENTER_CREATOR',
}

export enum ProjectUserRoles {
  FINANCIAL_ADMIN = 'FINANCIAL_ADMIN',
  ADMIN = 'ADMIN',
  USER = 'USER',
  INVOICE_APPROVER = 'INVOICE_APPROVER',
}

export const AdminRolesArray = [
  AdminRoles.FAKTURA_ADMIN,
  AdminRoles.POSTOS_ADMIN,
]

export const CreatorRolesArray = [
  CreatorRoles.FAKTURA_PROJECT_CREATOR,
  CreatorRoles.COMPANY_CREATOR,
  CreatorRoles.COSTCENTER_CREATOR
]

export const ProjectUserRolesArray = [
  ProjectUserRoles.FINANCIAL_ADMIN,
  ProjectUserRoles.ADMIN,
  ProjectUserRoles.USER,
  ProjectUserRoles.INVOICE_APPROVER
]

export const allRoles = { AdminRoles, ProjectUserRoles, CreatorRoles }

export default allRoles;
