import {
  SimpleGrid,
  Stack,
  Text,
  Flex,
  useToast,
  Container,
  Checkbox,
  Button,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCostCenterStore } from '../../../../contexts/globalStoreCostCenters';
import Card from '../../../../components/card/Card';
import { MdChevronLeft } from 'react-icons/md';
import useUserDataRolesStore from 'contexts/authStore';
import { checkIfUserCostCenterCreator } from 'utils/roleHelpers';
import useFormErrorsStore from 'contexts/formErrorsStore';

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required('nameRequired')
    .max(256),
  description: yup
    .string()
    .max(10240, 'descriptionLength'),
});

export default function AddEditCostCenter() {
  const toast = useToast();
  const navigate = useNavigate();
  let { id } = useParams();
  const location = useLocation();

  const userData = useUserDataRolesStore((state) => state.userData);

  const fetchSingleCostCenter = useCostCenterStore(
    (state) => state.fetchSingleCostCenter,
  );

  const singleCostCenterData = useCostCenterStore((state) => state.singleCostCenterData);

  const resetData = useCostCenterStore((state) => state.reset);
  const submitData = useCostCenterStore((state) => state.addOrEditCostCenterCall);
  const setData = useCostCenterStore((state) => state.set);

  const resetErrors = useFormErrorsStore((state) => state.resetErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const setShowErrors = useFormErrorsStore((state) => state.setShowErrors);

  const errors = useFormErrorsStore((state) => state.errors);
  const setErrors = useFormErrorsStore((state) => state.setErrors);

  const userRoles = useUserDataRolesStore((state) => state.userRoles);

  const isUserCostCenterCreator = useMemo(() => checkIfUserCostCenterCreator(userRoles), [userRoles]);

  const viewOnly = useMemo(() => !isUserCostCenterCreator, [isUserCostCenterCreator]);

  const [enableSubmit, setEnableSubmit] = useState(false);

  const [eachFieldStateValidation, setEachFieldStateValidation] = useState({
    name: { valid: true, error: '' },
    description: { valid: true, error: '' },
  });

  const handleChange = useCallback(
    (field: any, value: any) => {
      setData((state: any) => {
        state.singleCostCenterData[field] = value;
      });
    }, [setData]
  )

  const validateSingleField = useCallback((path: string, input: any) => {
    // `reach()` pulls out a child schema so we can test a single path
    const field: any = yup.reach(validationSchema, path);

    field
      .validate(input)
      .then(() =>
        setEachFieldStateValidation({
          ...eachFieldStateValidation,
          [path]: { valid: true, error: '' },
        }),
      ) // if valid set true
      .catch((e: any) => {
        setEachFieldStateValidation({
          ...eachFieldStateValidation,
          [path]: { valid: false, error: e.errors.join(', ') },
        });
        setErrors([...errors,
        e.errors.map((e: any) => t(e, { ns: ['errors'] })).join(', ')
        ]);
      }); // if invalid set false
  }, [eachFieldStateValidation, errors, setErrors]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      if (id) {
        fetchSingleCostCenter(Number(id))
          .then((res: any) => {
            if (!res || res.type !== 'success') {
              toast({
                title: t('error', { ns: ['labels'] }),
                description: res.message,
                status: res.type,
              });
            }
          })
          .catch((err) => {
            toast({
              title: t('error', { ns: ['labels'] }),
              description: err.message,
              status: err.type,
            });
          });
      } else {
        resetData();
        setEnableSubmit(false);
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [fetchSingleCostCenter, id, location.pathname, resetData, toast]);

  useEffect(() => {
    const validation = async () => {
      try {
        await validationSchema.validate(singleCostCenterData, {
          abortEarly: false,
          context: singleCostCenterData,
        });
        setEnableSubmit(true);
      } catch (err) {
        // Quick solution
        validateSingleField('name', singleCostCenterData.name);
        setEnableSubmit(false);
      }
    };

    validation();
    // Don't add validateSingleField to the deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleCostCenterData]);

  useEffect(() => {
    setShowErrors(false);
    resetErrors();
    return () => { resetErrors(); };
  }, [resetErrors, setShowErrors]);

  const handleDataSubmit = () => {
    if (!enableSubmit) {
      toast({
        title: t('error', { ns: ['labels'] }),
        description: errors.map((error, index) => (<Text key={index}>
          {error}
        </Text>)),
        duration: null,
        status: 'warning',
      });
      setShowErrors(true);
      return;
    } else {
      setShowErrors(false);
    }
    submitData(userData.id)
      .then((res) => {
        if (res) {
          toast({
            title:
              res.type === 'success'
                ? t('success', { ns: ['labels'] })
                : t('error', { ns: ['labels'] }),
            description: res.message,
            status: res.type,
          });
          if (!id) navigate(`/cost-centers/edit/${res.navigateId}`);
        }
      })
      .catch((err) => {
        toast({
          title: t('error', { ns: ['labels'] }),
          description: err.message,
          status: err.type,
        });
      });
  };
  const textColor = useColorModeValue('black', 'white');

  return (
    <Flex
      direction="column"
      align="center"
      pt={{ sm: '125px', lg: '75px' }}
      position="relative"
    >
      <Container maxW="960px">
        <Card p={8} w="100%">
          <Stack direction="row" w="100%" justifyContent="space-between" mb={4}>
            <Button
              variant="outline"
              fontSize="sm"
              borderRadius="12px"
              w={{ base: '128px', md: '148px' }}
              h="46px"
              data-test-id="cost-center-form-back-to-list-button"
              onClick={() => navigate('/cost-centers')}
            >
              <Icon as={MdChevronLeft} mr={1} />
              {t('back', { ns: ['actions'] })}
            </Button>
            {!viewOnly ? <Button
              variant="darkBrand"
              fontSize="sm"
              borderRadius="12px"
              w={{ base: '128px', md: '148px' }}
              h="46px"
              data-test-id="cost-center-form-submit-button"
              onClick={handleDataSubmit}
            >
              {t('submit', { ns: ['labels'] })}
            </Button> : null}
          </Stack>
          <Flex direction="column" w="100%">
            <SimpleGrid columns={{ base: 2 }} gap={4}>
              <Stack direction="column" gap={4}>
                <Flex direction="column">
                  <InputField
                    variant="main"
                    h="146px"
                    mb="0px"
                    id="name"
                    color={textColor}
                    placeholder={t('name', { ns: ['labels'] })}
                    value={singleCostCenterData?.name || ''}
                    label={t('name', { ns: ['labels'] }) + '*'}
                    data-test-id="cost-center-form-name-input"
                    onChange={(event: any) => {
                      handleChange('name', event.target.value);
                      validateSingleField('name', event.target.value);
                    }}
                    disabled={viewOnly}
                  />
                  <Text data-test-id="booking-form-name-input-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                    {showErrors && eachFieldStateValidation?.['name']?.error
                      ? t(eachFieldStateValidation['name'].error, { ns: ["hints"] }) : null} &nbsp;
                  </Text>
                </Flex>
              </Stack>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 2 }} gap={4}>
              <Stack direction="column" gap={4}>
                <Flex direction="column">
                  <TextField
                    variant="main"
                    h="146px"
                    mb="0px"
                    id="description"
                    color={textColor}
                    placeholder={t('description', { ns: ['labels'] })}
                    value={singleCostCenterData?.description || ''}
                    label={t('description', { ns: ['labels'] })}
                    data-test-id="cost-center-form-description-input"
                    onChange={(event: any) => {
                      handleChange('description', event.target.value);
                      validateSingleField('description', event.target.value);
                    }}
                    disabled={viewOnly}
                  />
                  <Text data-test-id="booking-form-description-input-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                    {t(eachFieldStateValidation['description'].error, { ns: ["hints"] })} &nbsp;
                  </Text>
                </Flex>
              </Stack>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1 }} gap={0}>
              <Stack direction="column" gap={0}>
                <Checkbox
                  mb={4}
                  isChecked={singleCostCenterData?.isBillable}
                  data-test-id="cost-center-form-isBillable-checkbox"
                  onChange={(event: any) => {
                    handleChange('isBillable', event.target.checked);
                  }}
                  disabled={viewOnly}
                >
                  {t("isBillable", { ns: ["labels"] })}
                </Checkbox>
              </Stack>
            </SimpleGrid>
          </Flex>
        </Card>
      </Container>
    </Flex>
  );
}
