import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Stack,
  FormControl,
  Text,
  Flex,
  IconButton,
  SimpleGrid,
  InputGroup,
  Input,
  InputRightAddon,
  Card,
  useColorModeValue,
  useToast,
  FormLabel,
  Spinner,
  Box,
  Select,
} from '@chakra-ui/react';
import { ActionMeta, MultiValue, Select as SelectWithMultipleOption } from 'chakra-react-select';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { ProjectUser, useProjectStore } from 'contexts/globalStoreProjects';
import { t } from 'i18next';
import * as yup from 'yup';
import useUserDataRolesStore from 'contexts/authStore';
import { useFakturaUsersStore, User } from 'contexts/globalStoreFakturaUsers';
import { ProjectUserRoles, ProjectUserRolesArray } from 'enums/userRoles';
import { checkIfUserFakturaAdmin, checkIfUserProjectCreator } from 'utils/roleHelpers';
import useFormErrorsStore from 'contexts/formErrorsStore';
import { handleFloatInputWithStoringDataFunction } from 'utils/valuesHelpers';

type SelectedProjectUser = Omit<ProjectUser, 'role' | 'id' | 'projectId'>
  & { roles: string[], email: string, name: string, hasErrors: boolean };

interface RoleOption {
  value: string;
  label: string;
}

const rateSchema = yup
  .number()
  .positive()
  .typeError('typeErrorNumber')
  .required('rateRequired')

const validationSchemaSingleUser = yup.object().shape({
  userId: yup.number().typeError('typeErrorUser').required('typeErrorUser'),
  role: yup.string().required('roleRequired'),
  hoursPerMonth: yup.number().nullable(),
  userRate: yup
    .number()
    .when('role', {
      is: ProjectUserRoles.USER,
      then: () => rateSchema,
      otherwise: () => yup.mixed().notRequired().nullable()
    }),
  clientRate: yup
    .number()
    .when('role', {
      is: ProjectUserRoles.USER,
      then: () => rateSchema,
      otherwise: () => yup.mixed().notRequired().nullable()
    }),
});

const validationSchemaSingleUserNoRates = yup.object().shape({
  userId: yup.number().typeError('typeErrorUser').required('typeErrorUser'),
  role: yup.string().required('roleRequired'),
});

const validationSchema = yup.object().shape({
  users: yup
    .array()
    .of(validationSchemaSingleUser)
    .min(1, 'atLeastOneTechAdmin')
    .required()
    .test(
      'at-least-one-admin',
      'atLeastOneTechAdmin',
      (users) => users && users.some(user => user.role === ProjectUserRoles.ADMIN)
    ),
});

const validationSchemaNoRates = yup.object().shape({
  users: yup
    .array()
    .of(validationSchemaSingleUserNoRates)
    .min(1, 'atLeastOneTechAdmin')
    .required()
    .test(
      'at-least-one-admin',
      'atLeastOneTechAdmin',
      (users) => users && users.some(user => user.role === ProjectUserRoles.ADMIN)
    ),
});

interface ProjectUsersValidation {
  [userId: number]: {
    userId: {
      valid: boolean;
      error: string;
    };
    role: {
      valid: boolean;
      error: string;
    };
    hoursPerMonth?: {
      valid: boolean;
      error: string;
    };
    userRate?: {
      valid: boolean;
      error: string;
    };
    clientRate?: {
      valid: boolean;
      error: string;
    };
  }
}

export const ProjectPermissions = ({
  viewOnly, isUserFinancialAdminOnProject
}: {
  viewOnly: boolean,
  isUserFinancialAdminOnProject: boolean
}) => {
  const textColor = useColorModeValue('navy.700', 'white');

  const toast = useToast();

  const projectUsers = useProjectStore((state) => state.projectUsers);
  const projectUsersInDB = useProjectStore((state) => state.projectUsersInDB);
  const projectId = useProjectStore((state) => state.project.id);

  const userData = useUserDataRolesStore((state) => state.userData);
  const userRoles = useUserDataRolesStore((state) => state.userRoles);

  const setErrors = useFormErrorsStore((state) => state.setErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const deleteError = useFormErrorsStore((state) => state.deleteError);
  const deleteErrorsContainingString = useFormErrorsStore((state) => state.deleteErrorsContainingString);

  const isUserProjectCreator = useMemo(() => checkIfUserProjectCreator(userRoles), [userRoles]);
  const isUserFakturaAdmin = useMemo(() => checkIfUserFakturaAdmin(userRoles), [userRoles]);

  const allUsers = useFakturaUsersStore((state) => state.users);
  const fetchUsers = useFakturaUsersStore((state) => state.fetchUsers);

  const [eachFieldProjectUserValidation, setEachFieldProjectUserValidation] =
    useState<ProjectUsersValidation>([]);

  const checkIfUserHasErrors = useCallback((userId: number) => {
    if (!eachFieldProjectUserValidation?.[userId]) return false;

    const validations = Object.values(eachFieldProjectUserValidation?.[userId]);
    return validations.findIndex(validation => Boolean(validation.error)) !== -1;
  }, [eachFieldProjectUserValidation]);

  const sortedProjectUsers = useMemo(() => {
    const projectUsersWithAddedInfo: SelectedProjectUser[] = [];

    projectUsers.forEach(pu => {
      const addedUserIds = projectUsersWithAddedInfo.map(pu => pu.userId);

      if (addedUserIds.includes(pu.userId)) {
        projectUsersWithAddedInfo.find(puwn => puwn.userId === pu.userId).roles.push(pu.role);

      } else {
        const user = allUsers.find((u) => u.userId === pu.userId);
        const userHasFormErrors = showErrors && checkIfUserHasErrors(pu.userId);

        projectUsersWithAddedInfo.push({
          userId: pu.userId,
          email: user?.email || '',
          name: user?.name || '',
          roles: [pu.role],
          clientRate: pu.clientRate,
          hoursPerMonth: pu.hoursPerMonth,
          userRate: pu.userRate,
          hasErrors: userHasFormErrors,
        });
      }

      if (pu.role === ProjectUserRoles.USER) {
        const userInList = projectUsersWithAddedInfo.find(puwn => puwn.userId === pu.userId);
        if (userInList) {
          userInList.clientRate = pu.clientRate;
          userInList.hoursPerMonth = pu.hoursPerMonth;
          userInList.userRate = pu.userRate;
        }
      }
    })

    return [...projectUsersWithAddedInfo]
      .sort((a, b) => a.email && b.email ? a.email.localeCompare(b.email) : 0);
  }, [allUsers, checkIfUserHasErrors, projectUsers, showErrors]);

  const addNewUserToProject = useProjectStore(
    (state) => state.addUserToProject,
  );
  const deleteUserFromProjectList = useProjectStore(
    (state) => state.deleteUserFromProject,
  );

  const changeUserInProject = useProjectStore(
    (state) => state.changeUserInProject,
  );

  const areProjectUsersLoading = useProjectStore(
    (state) => state.areProjectUsersLoading,
  );

  const [selectedUser, setSelectedUser] = useState<SelectedProjectUser>(null);
  const [selectedRoles, setSelectedRoles] = useState<RoleOption[]>([])
  const selectedRolesValues = useMemo(() => selectedRoles.map(r => r.value), [selectedRoles])

  const getValidationError = useCallback((fieldName: keyof ProjectUsersValidation[number]) => {
    return eachFieldProjectUserValidation?.[selectedUser.userId]?.[fieldName]?.error || null
  }, [eachFieldProjectUserValidation, selectedUser]);

  useEffect(() => { fetchUsers() }, [fetchUsers]);

  const handleDeleteSelect = useCallback((userIdToDelete: number) => {
    const projectUsersOfThisUser = projectUsers.filter(pu => pu.userId === userIdToDelete);

    projectUsersOfThisUser.forEach(pu => {
      deleteUserFromProjectList(pu.id);
    })

    if (selectedUser?.userId === userIdToDelete) {
      setSelectedUser(null);
      setSelectedRoles([]);
    }
  }, [deleteUserFromProjectList, projectUsers, selectedUser?.userId]);

  const handleUserData = useCallback((userId: number, field: any, value: any) => {
    setSelectedUser({ ...selectedUser, [field]: value })
    const projectUsersOfThisUser = projectUsers.filter(pu => pu.userId === userId);

    if (['hoursPerMonth', 'userRate', 'clientRate'].includes(field)) {
      const projectUserRoleUser = projectUsersOfThisUser.find(pu => pu.role === ProjectUserRoles.USER);

      if (projectUserRoleUser) {
        changeUserInProject(projectUserRoleUser.id, field, value);
      }
    }
  }, [changeUserInProject, projectUsers, selectedUser]);

  const setTrue = useProjectStore((state) => state.setToTrue);

  const validateFormData = useCallback(async (formData: any) => {
    const schema = isUserFakturaAdmin || isUserFinancialAdminOnProject ? validationSchema : validationSchemaNoRates
    try {
      await schema.validate(formData);
      setTrue('permissionValidation', true);
    } catch (error) {
      setTrue('permissionValidation', false);
    }
  }, [isUserFakturaAdmin, isUserFinancialAdminOnProject, setTrue]);

  useEffect(() => { validateFormData({ users: projectUsers }) }, [validateFormData, projectUsers]);

  const validateSingleField = useCallback(async (path: keyof ProjectUser, user: ProjectUser) => {
    if ((path === 'clientRate' || path === 'userRate') // financial info and
      && (user.role !== ProjectUserRoles.USER // doesn't need rates
        || (!isUserFakturaAdmin && !isUserFinancialAdminOnProject))) { // or can't see financial info
      setEachFieldProjectUserValidation(prevState => ({
        ...prevState,
        [user.userId]: {
          ...prevState[user.userId],
          [path]: { valid: true, error: '' },
        }
      }));
      return
    }
    try {
      const schema = isUserFakturaAdmin || isUserFinancialAdminOnProject ? validationSchemaSingleUser : validationSchemaSingleUserNoRates
      await schema.validateAt(path, user, { abortEarly: false });

      setEachFieldProjectUserValidation(prevState => ({
        ...prevState,
        [user.userId]: {
          ...prevState[user.userId],
          [path]: { valid: true, error: '' },
        }
      }));

    } catch (e: any) {
      if (e instanceof yup.ValidationError) {
        setEachFieldProjectUserValidation(prevState => ({
          ...prevState,
          [user.userId]: {
            ...prevState[user.userId],
            [path]: { valid: false, error: e.errors.join(', ') },
          }
        }));

        return e.errors;
      } else {
        setEachFieldProjectUserValidation(prevState => ({
          ...prevState,
          [user.userId]: {
            ...prevState[user.userId], [path]: { valid: false, error: String(e) },
          }
        }));
        return e;
      }
    }
  }, [isUserFakturaAdmin, isUserFinancialAdminOnProject]);

  useEffect(() => {
    if (!isUserFinancialAdminOnProject && !isUserFakturaAdmin) return

    [...projectUsers].forEach(async (user: ProjectUser) => ({
      userRate: user.role === ProjectUserRoles.USER && await validateSingleField('userRate', user),
      clientRate: user.role === ProjectUserRoles.USER && await validateSingleField('clientRate', user),
    }));
  }, [projectUsers, validateSingleField, selectedUser, isUserFinancialAdminOnProject, isUserFakturaAdmin]);

  useEffect(() => {
    const userError = t('userError', { ns: ['errors'] });
    const atLeastOneTechAdminError = `${userError}: ${t('atLeastOneTechAdmin', { ns: ['hints'] })}`;
    if (projectUsers?.length && projectUsers.some(projectUser => projectUser.role === ProjectUserRoles.ADMIN)) {
      deleteError(atLeastOneTechAdminError);
    } else {
      setErrors([atLeastOneTechAdminError]);
    }
  }, [deleteError, projectUsers, setErrors]);

  useEffect(() => {
    const userError = t('userError', { ns: ['errors'] });

    const errorsForShow: string[] = []

    Object.entries({ ...eachFieldProjectUserValidation })
      .forEach(([_, fieldsValidation]) => {
        Object.values(fieldsValidation as Record<string, { valid: boolean, error: string }>)
          .forEach((fieldValidation) => {
            if (!fieldValidation.valid || fieldValidation.error) {
              errorsForShow.push(`${userError}: ${t(fieldValidation.error, { ns: ['hints'] })}`)
            }
          })
      })

    if (errorsForShow.length > 0) {
      setErrors(errorsForShow);
    } else {
      deleteErrorsContainingString(userError);
    }
  }, [deleteError, deleteErrorsContainingString, eachFieldProjectUserValidation, setErrors]);

  const [showAddPermissionForm, setShowAddPermissionForm] = useState(false);
  const [userToAdd, setUserToAdd] = useState({ userId: null, role: null });

  const addNewPermission = useCallback(() => {
    if (!userToAdd.userId || !userToAdd.role) {
      toast({
        title: t('error', { ns: ['errors'] }),
        description: t('userAndRoleRequired', { ns: ['errors'] }),
        status: 'error',
        duration: 5000,
      })
      return
    }

    const newPermission: ProjectUser = {
      id: -Date.now(), // Negative temporary ID to handle state without breaking the sortings
      userId: Number(userToAdd.userId),
      role: userToAdd.role.value,
      userRate: null,
      clientRate: null,
      hoursPerMonth: null,
      projectId: projectId || null,
    }

    addNewUserToProject(newPermission);
  }, [addNewUserToProject, projectId, toast, userToAdd]);

  const addDefaultAdmin = useCallback(() => {
    const newUser: ProjectUser = {
      userId: userData.id,
      role: ProjectUserRoles.ADMIN,
      userRate: null,
      clientRate: null,
      hoursPerMonth: null,
      projectId: projectId || null,
    }
    addNewUserToProject(newUser);
  }, [addNewUserToProject, projectId, userData.id]);

  const [isInit, setIsInit] = useState(true);

  useEffect(() => {
    if (projectUsers.length > 0 || !isInit) {
      return
    }
    // Quick fix of reset data (in AddEdit) after adding default admin
    setTimeout(() => {
      addDefaultAdmin()
    }, 1);

    setIsInit(false);
  }, [addDefaultAdmin, isInit, projectUsers.length]);

  const selectUser = useCallback((pu: SelectedProjectUser) => {
    setSelectedUser(pu);
    setSelectedRoles(pu.roles.map((role: string) => ({ label: role, value: role })))
  }, [])

  const userRolesAvailableForSelect: RoleOption[] = useMemo(() => {
    if (!selectedUser) return [];

    const currentUserRoles = projectUsers
      .filter(pu => pu.userId === selectedUser.userId && !selectedUser?.roles?.includes(pu.role))
      .map(pu => pu.role)

    return ProjectUserRolesArray
      .filter(role => !currentUserRoles.includes(role))
      .map(role => {
        const cantChange =
          (role === ProjectUserRoles.FINANCIAL_ADMIN && !isUserFakturaAdmin && !isUserFinancialAdminOnProject)
          ||
          (role === ProjectUserRoles.INVOICE_APPROVER && !isUserFinancialAdminOnProject)
        return {
          label: t(role, { ns: ['status'] }) + (cantChange ? ` (${t('cantChange', { ns: ['labels'] })})` : ''),
          value: role as string
        }
      })
  }, [isUserFakturaAdmin, isUserFinancialAdminOnProject, projectUsers, selectedUser]);

  const usersAvailableToAdd = useMemo(() => {
    return allUsers
      .filter((user) =>
        !sortedProjectUsers.map(user => user.userId)
          .includes(user.userId)
      )
  }, [allUsers, sortedProjectUsers]);

  const userRolesInAddForm: RoleOption[] = useMemo(() => {
    return ProjectUserRolesArray
      .map(role => {
        const cantChange =
          (role === ProjectUserRoles.FINANCIAL_ADMIN && !isUserFakturaAdmin && !isUserFinancialAdminOnProject)
          ||
          (role === ProjectUserRoles.INVOICE_APPROVER && !isUserFinancialAdminOnProject)
        return {
          label: t(role, { ns: ['status'] }) + (cantChange ? ` (${t('cantChange', { ns: ['labels'] })})` : ''),
          value: role as string
        }
      })
  }, [isUserFakturaAdmin, isUserFinancialAdminOnProject]);

  useEffect(() => {
    if (selectedUser
      && !selectedRolesValues?.includes(ProjectUserRoles.USER)
      && (selectedUser.hoursPerMonth !== null || selectedUser.userRate !== null || selectedUser.clientRate !== null)) {
      setSelectedUser({ ...selectedUser, hoursPerMonth: null, userRate: null, clientRate: null })
    }
  }, [selectedRolesValues, selectedUser])

  const updateProjectUsersAccordingToRoles = useCallback((roles: string[]) => {
    if (!selectedUser) return
    if (!roles.length) {
      projectUsers.filter(pu => pu.userId === selectedUser?.userId).forEach(pu => {
        deleteUserFromProjectList(pu.id)
      })
      setSelectedUser(null)
      return
    }

    roles.forEach((role) => {
      const projectUserInDB = projectUsersInDB.find( // Check DB in case it exists with the "normal" id
        (pu) => pu.user_id === selectedUser.userId && pu.role === role
      );
      const projectUserLocally = projectUsers.find(
        (pu) => pu.userId === selectedUser.userId && pu.role === role
      )

      if (!projectUserLocally) {
        addNewUserToProject({
          id: projectUserInDB?.id || -Date.now(), // Negative temporary ID to handle state without breaking the sortings
          userId: selectedUser.userId,
          role,
          userRate: selectedUser.userRate,
          clientRate: selectedUser.clientRate,
          hoursPerMonth: selectedUser.hoursPerMonth,
          projectId: projectId || null,
        });
      } else {
        changeUserInProject(projectUserLocally?.id, 'role', role);
      }
    })

    projectUsers.filter(pu => pu.userId === selectedUser.userId).forEach(pu => {
      if (!roles.includes(pu.role)) {
        deleteUserFromProjectList(pu.id)
      }
    })
  }, [addNewUserToProject, changeUserInProject, deleteUserFromProjectList, projectId, projectUsers, projectUsersInDB, selectedUser])

  const handleRolesChange = useCallback((selectedOptions: MultiValue<RoleOption>, actionMeta: ActionMeta<RoleOption>) => {
    if (actionMeta.action === "remove-value" || actionMeta.action === "pop-value") {
      const removedOption = actionMeta.removedValue;
      if (
        ((removedOption?.value === ProjectUserRoles.FINANCIAL_ADMIN &&
          !isUserFakturaAdmin &&
          !isUserFinancialAdminOnProject) ||
          (removedOption?.value === ProjectUserRoles.INVOICE_APPROVER &&
            !isUserFinancialAdminOnProject))
      ) {
        toast({
          title: t('error', { ns: ['labels'] }),
          description: t('cantChange', { ns: ['labels'] }),
          duration: 2000,
          status: 'error'
        });
        return;
      }
    }
    setSelectedRoles(selectedOptions as RoleOption[])
    setSelectedUser({ ...selectedUser, roles: selectedOptions.map(r => r.value) })

    updateProjectUsersAccordingToRoles(selectedOptions.map(r => r.value))
  }, [updateProjectUsersAccordingToRoles, isUserFakturaAdmin, isUserFinancialAdminOnProject, selectedUser, toast])

  const selectedUserData = useMemo(() => {
    if (!selectedUser) return { name: '', email: '' }
    const { name, email } = allUsers.find(u => u.userId === selectedUser.userId) || { name: '', email: '' };
    return { name, email }
  }, [allUsers, selectedUser]);

  return (
    <>
      {!viewOnly ? <Flex my={4} pr={4}>
        {!showAddPermissionForm ? <Button
          variant="outline"
          data-testid="open-form-add-permission-button"
          onClick={() => setShowAddPermissionForm(true)}
        >
          {t('addPermission', { ns: ['labels'] })}
          <AddIcon ml={2} />
        </Button> : null}
        {showAddPermissionForm ?
          <Stack direction={{ base: 'column', md: 'row' }} alignItems={'flex-end'} mb={2}>
            <Stack direction="column">
              <FormLabel
                ms="10px"
                fontSize="sm"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
                mb={0}
              >
                {t('user', { ns: ['labels'] }) + '*'}
              </FormLabel>
              <Select
                value={userToAdd.userId || ''}
                placeholder={t('selectUser', { ns: ['labels'] })}
                disabled={viewOnly}
                required
                data-test-id={`permission-user-select`}
                zIndex={2}
                onChange={(event) => setUserToAdd({ ...userToAdd, userId: event.target.value })}
              >
                {usersAvailableToAdd.map(
                  (user: User) => (
                    <option key={user.userId} value={user.userId}>
                      {user.name} ({user.email}){user.status === 'ACTIVE' ? '' : ` (${t('inactive', { ns: ['labels'] })})`}
                    </option>
                  )
                )}
              </Select>
            </Stack>
            <SelectWithMultipleOption
              value={userToAdd.role}
              data-test-id={`add-permission-role-select`}
              placeholder={t('selectFirstRole', { ns: ['labels'] })}
              options={userRolesInAddForm}
              isOptionDisabled={option =>
                (option.value === ProjectUserRoles.FINANCIAL_ADMIN
                  && !isUserFakturaAdmin
                  && !isUserFinancialAdminOnProject)
                ||
                (option.value === ProjectUserRoles.INVOICE_APPROVER
                  && !isUserFinancialAdminOnProject)
              }
              // getOptionLabel={option => t(option.label, { ns: ['status'] })}
              chakraStyles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  background: 'transparent',
                  p: 0,
                  w: "30px",
                }),
              }}
              onChange={(e) => setUserToAdd({ ...userToAdd, role: e })}
            />
            <Button
              variant="outline"
              data-testid="add-permission-button"
              onClick={() => addNewPermission()}
            >
              {t('add', { ns: ['labels'] })}
            </Button>
            <Button
              variant="outline"
              data-testid="add-permission-button"
              onClick={() => {
                setShowAddPermissionForm(false)
                setUserToAdd({ userId: null, role: null })
              }}
            >
              {t('cancel', { ns: ['labels'] })}
            </Button>
          </Stack> : null}
      </Flex> : null}

      {areProjectUsersLoading ? <Spinner /> : sortedProjectUsers?.length ? (
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="4">
          <Stack>
            {sortedProjectUsers.map(pu => (
              <Box
                key={pu.userId}
                textAlign="left"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={2}
                px={3}
                height="auto"
                data-test-id={`permission-user-${pu.userId}`}
                onClick={() => selectUser(pu)}
                width="100%"
                borderRadius={"md"}
                cursor={"pointer"}
                style={pu.hasErrors
                  ? { color: 'red' }
                  : selectedUser?.userId === pu.userId
                    ? { backgroundColor: "rgba(117, 81, 255, 0.5)" }
                    : {}}
                _hover={{ bg: "rgba(117, 81, 255, 0.5)" }}
                _active={{ bg: "rgba(117, 81, 255, 0.5)" }}
              >
                <Box>
                  {pu.name && pu.email ? (
                    <>
                      {pu.name} <br /> ({pu.email})
                    </>
                  ) : (
                    t('selectUser', { ns: ['labels'] })
                  )}
                </Box>

                <IconButton
                  ml="4"
                  colorScheme="red"
                  aria-label="Delete Select"
                  icon={<DeleteIcon />}
                  isDisabled={viewOnly}
                  data-test-id={`permission-delete-button`}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents parent click event
                    handleDeleteSelect(pu.userId);
                  }}
                />
              </Box>))}
          </Stack>

          {selectedUser ? <Card p={4}>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              alignItems="center"
              mb={3}
            >
              <Text>{selectedUserData.name}<br />({selectedUserData.email})</Text>
            </Stack>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              alignItems="center"
              mb={3}
            >
              <FormControl>
                <Stack direction="column">
                  <FormLabel
                    ms="10px"
                    htmlFor="costCenterNonBillable"
                    fontSize="sm"
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                    mb={0}
                  >
                    {t('role', { ns: ['labels'] }) + '*'}
                  </FormLabel>
                  <SelectWithMultipleOption
                    value={selectedRoles}
                    data-test-id={`permission-role-select`}
                    isMulti
                    placeholder={t('selectRoles', { ns: ['labels'] })}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    isDisabled={
                      viewOnly ||
                      ((selectedRolesValues.includes(ProjectUserRoles.FINANCIAL_ADMIN)
                        || selectedRolesValues.includes(ProjectUserRoles.INVOICE_APPROVER))

                        && !isUserFinancialAdminOnProject
                        && !isUserProjectCreator
                        && !isUserFakturaAdmin)
                    }
                    options={userRolesAvailableForSelect}
                    isOptionDisabled={option =>
                      (option.value === ProjectUserRoles.FINANCIAL_ADMIN
                        && !isUserFakturaAdmin
                        && !isUserFinancialAdminOnProject)
                      ||
                      (option.value === ProjectUserRoles.INVOICE_APPROVER
                        && !isUserFinancialAdminOnProject)
                    }
                    getOptionLabel={option => t(option.label, { ns: ['status'] })}
                    chakraStyles={{
                      control: (provided) => ({
                        ...provided,
                        width: '100%',
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        background: 'transparent',
                        p: 0,
                        w: "30px",
                      }),
                    }}
                    onChange={handleRolesChange}
                  />
                  {showErrors && getValidationError('role') ?
                    <Text data-test-id={`permission-role-error`} style={{ marginTop: 0 }} pl={2} fontSize="sm" color="red.500">
                      {t(getValidationError('role'), { ns: ['hints'] })}{' '}
                      &nbsp;
                    </Text> : null}
                </Stack>
              </FormControl>
            </Stack>
            {selectedRolesValues.includes(ProjectUserRoles.USER) ? (
              <SimpleGrid columns={{ base: 1, md: 1 }} spacingX="20px" mb={3}>
                <FormLabel
                  ms="10px"
                  htmlFor="hoursPerMonth"
                  fontSize="sm"
                  fontWeight="bold"
                  _hover={{ cursor: 'pointer' }}
                >
                  {t('hoursPerMonth', { ns: ['labels'] })}
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    value={selectedUser.hoursPerMonth || ''}
                    color={textColor}
                    placeholder={t('hoursPerMonth', { ns: ['labels'] })}
                    disabled={
                      viewOnly
                      || !selectedUser?.roles?.includes(ProjectUserRoles.USER)
                      || !selectedRolesValues?.includes(ProjectUserRoles.USER)
                    }
                    data-test-id={`permission-hours-per-month-input`}
                    onChange={(event) => handleUserData(selectedUser.userId, 'hoursPerMonth', Number(event.target.value))}
                  />
                  <InputRightAddon children="/h" />
                </InputGroup>
                {showErrors && getValidationError('hoursPerMonth')
                  ? <Text data-test-id={`permission-hours-per-month-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
                    {t(getValidationError('hoursPerMonth'), { ns: ['hints'] })}{' '}
                    &nbsp;
                  </Text> : null}
                {isUserFakturaAdmin || isUserFinancialAdminOnProject ? (
                  <Stack direction={{ base: 'column', md: 'row' }} mt={3}>
                    <Flex direction="column" w="100%">
                      <FormLabel
                        ms="10px"
                        fontSize="sm"
                        fontWeight="bold"
                        _hover={{ cursor: 'pointer' }}
                      >
                        {t('userRate', { ns: ['labels'] }) + '*'}
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          value={selectedUser.userRate || 0}
                          color={textColor}
                          placeholder={t('userRate', { ns: ['labels'] })}
                          disabled={
                            viewOnly
                            || (!isUserFakturaAdmin && !isUserFinancialAdminOnProject)
                          }
                          data-test-id={`user-rate-input`}
                          onChange={(event) => {
                            handleFloatInputWithStoringDataFunction(
                              event.target.value,
                              (val: string | number) => handleUserData(selectedUser.userId, 'userRate', val)
                            )
                          }}
                        />
                        <InputRightAddon children="/h" />
                      </InputGroup>
                      <Text data-test-id={`user-rate-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
                        {showErrors && getValidationError('userRate')
                          ? t(getValidationError('userRate'), { ns: ['hints'] }) : null}{' '}
                        &nbsp;
                      </Text>
                    </Flex>
                    <Flex direction="column" w="100%">
                      <FormLabel
                        ms="10px"
                        fontSize="sm"
                        fontWeight="bold"
                        _hover={{ cursor: 'pointer' }}
                      >
                        {t('clientRate', { ns: ['labels'] }) + '*'}
                      </FormLabel>
                      <InputGroup size="md">
                        <Input
                          color={textColor}
                          value={selectedUser.clientRate || 0}
                          placeholder={t('clientRate', { ns: ['labels'] })}
                          disabled={
                            viewOnly
                            || (!isUserFakturaAdmin && !isUserFinancialAdminOnProject)
                          }
                          data-test-id={`client-rate-input`}
                          onChange={(event) => {
                            handleFloatInputWithStoringDataFunction(
                              event.target.value,
                              (val: string | number) => handleUserData(selectedUser.userId, 'clientRate', val)
                            )
                          }}
                        />
                        <InputRightAddon children="/h" />
                      </InputGroup>
                      <Text data-test-id={`client-rate-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
                        {showErrors && getValidationError('clientRate')
                          ? t(getValidationError('clientRate'), { ns: ['hints'] }) : null}{' '}
                        &nbsp;
                      </Text>
                    </Flex>
                  </Stack>) : null}
              </SimpleGrid>) : null}
          </Card> : null}
        </SimpleGrid>) : null}
    </>
  );
};

export default ProjectPermissions;
