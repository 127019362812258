import { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  Stack,
  FormControl,
  Text,
  Flex,
  IconButton,
  SimpleGrid,
  Alert,
  useToast,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { CreatableSelect } from 'chakra-react-select';
import { Task, useProjectStore, ProjectTaskWithName } from 'contexts/globalStoreProjects';
import * as yup from 'yup';
import { t } from 'i18next';
import useFormErrorsStore from 'contexts/formErrorsStore';

const validationSchema = yup.object().shape({
  tasks: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .required()
      }),
    )
    .min(1, 'taskRequired')
    .required(),
});

export const ProjectTasks = ({ viewOnly }: { viewOnly: boolean }) => {
  const toast = useToast();

  const projectId = useProjectStore((state) => state.project.id);
  const allTasks = useProjectStore((state) => state.allTasks);
  const projectTasks = useProjectStore((state) => state.projectTasks);

  const addNewTaskToProject = useProjectStore(
    (state) => state.addTaskToProject,
  );
  const deleteTaskFromProject = useProjectStore(
    (state) => state.deleteTaskFromProject,
  );
  const changeTaskInProject = useProjectStore(
    (state) => state.changeTaskInProject,
  );

  const handleAddSelect = useCallback(() => {
    addNewTaskToProject({
      id: null,
      name: '',
      taskId: null,
      projectId
    });
  }, [addNewTaskToProject, projectId]);

  const handleDeleteSelect = (indexToDelete: number) => {
    deleteTaskFromProject(indexToDelete);
  };

  const handleChangeSelect = (indexToChange: number, newData: ProjectTaskWithName) => {
    const newTaskStoreResponse = changeTaskInProject(indexToChange, { ...newData });

    if (newTaskStoreResponse && newTaskStoreResponse === 'error') {
      toast({
        title: t('error', { ns: ['labels'] }),
        description: t('taskAlreadyExists', { ns: ['hints'] }),
      });
    }
  };

  const tasksOptionsWithoutAdded: Task[] = useMemo(() => {
    const filteredTasks = projectTasks?.length ? allTasks
      .filter(
        (taskFromAll) =>
          !projectTasks
            .find((projectTask) => projectTask.taskId === taskFromAll.id || projectTask.name === taskFromAll.name),
      ) : allTasks;

    return filteredTasks.map((task) => ({
      ...task,
      projectId,
    }))
  }, [allTasks, projectId, projectTasks]);

  const setTrue = useProjectStore((state) => state.setToTrue);

  const setErrors = useFormErrorsStore((state) => state.setErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const deleteError = useFormErrorsStore((state) => state.deleteError);

  const validateFormData = useCallback(async (formData: any) => {
    try {
      await validationSchema.validate(formData);
      setTrue('taskValidation', true);
      return true
    } catch (error) {
      setTrue('taskValidation', false);
      return false
    }
  }, [setTrue]);

  const getTaskName = useCallback((taskId: number) => {
    const found = allTasks.find((taskFromAll) => taskFromAll.id === taskId)
    return found?.name
  }, [allTasks]);

  useEffect(() => {
    const taskRequiredError =
      `${t('taskError', { ns: ['errors'] })}: ${t('taskRequired', { ns: ['hints'] })}`;

    // if (!projectTasks?.length) {
    //   setErrors([taskRequiredError])
    //   return
    // }

    validateFormData({
      tasks: projectTasks.map((task) => (
        { ...task, name: task.name || getTaskName(task.taskId) }
      ))
    }).then((result) => {
      if (!result) {
        setErrors([taskRequiredError]);
      } else {
        deleteError(taskRequiredError);
      }
    });
  }, [deleteError, getTaskName, projectTasks, setErrors, showErrors, validateFormData]);

  return (
    <>
      <Flex my={4} pr={4}>
        {!viewOnly ? <Button variant="outline" data-testid="add-project-task-button" onClick={handleAddSelect}>
          Add task
          <AddIcon ml={2} />
        </Button> : null}
      </Flex>
      {!projectTasks || projectTasks.length === 0 ? (
        <SimpleGrid columns={{ base: 1, md: 1 }} gap="20px">
          <Alert status="warning" borderRadius="12px">
            <Text fontSize="md">{t('tasksHint', { ns: ['hints'] })}</Text>
          </Alert>
        </SimpleGrid>
      ) : (
        <SimpleGrid columns={{ base: 1, md: 1 }} gap="20px">
          {projectTasks.map((projectTask, index) => {
            const taskInSelect = projectTask.taskId >= 1 // Not a temporary id for created tasks, see getNewOptionData below
              ? allTasks.find((task) => task.id === projectTask.taskId)
              : projectTask;
            return (
              <Flex key={index + 1000} alignItems={'center'}>
                <Text w={'30px'} mr={4}>{index + 1}.</Text>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  alignItems="center"
                  w={{ base: "100%", xl: "70%" }}
                >
                  <Flex w="100%">
                    <FormControl>
                      <Stack direction="column">
                        <CreatableSelect
                          id="tasks"
                          name="tasks"
                          value={taskInSelect}
                          closeMenuOnSelect={true}
                          options={[...tasksOptionsWithoutAdded].sort(
                            (a, b) => a.name.localeCompare(b.name)
                          )}
                          getNewOptionData={(_inputValue: any, optionLabel: any) => ({
                            // Temporary id for created tasks
                            id: Math.random(),
                            name: optionLabel,
                          })}
                          getOptionLabel={(task: Task) => task.name}
                          getOptionValue={(task: Task) => task?.id?.toString() || ''}
                          size="md"
                          isDisabled={viewOnly}
                          data-test-id={`task-${index}-input`}
                          onChange={(task: any) => {
                            const newProjectTask = { ...projectTask, name: task?.name, taskId: task?.id };
                            return handleChangeSelect(index, newProjectTask);
                          }}
                        />
                      </Stack>
                    </FormControl>
                    <IconButton
                      colorScheme="red"
                      ml="4"
                      aria-label="Delete Select"
                      icon={<DeleteIcon />}
                      isDisabled={viewOnly}
                      data-test-id={`task-${index}-delete-button`}
                      onClick={() => handleDeleteSelect(index)}
                    />
                  </Flex>
                </Stack>
              </Flex>
            );
          })}
        </SimpleGrid>
      )}
    </>
  );
};
