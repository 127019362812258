import { endOfMonth, format, startOfMonth, addMinutes, endOfDay } from "date-fns";
import { DateTime } from 'luxon';

export const getMonthRangeFromDateValue = (dateValue: string) => {
  const selectedDate = new Date(dateValue);
  const startDay = startOfMonth(selectedDate);
  const endDay = endOfDay(endOfMonth(selectedDate));
  // const formattedStartDay = format(startDay, 'yyyy-MM-dd');
  // const formattedEndDay = format(endDay, 'yyyy-MM-dd');
  return {
    startDay/* : formattedStartDay */,
    endDay/* : formattedEndDay */
  }
}

export const toISOWithoutTimezone = (d: Date) => {
  let pad = (number: number) => (number < 10 ? '0' + number : number);

  return (
    d.getFullYear() +
    '-' +
    pad(d.getMonth() + 1) +
    '-' +
    pad(d.getDate()) +
    'T' +
    pad(d.getHours()) +
    ':' +
    pad(d.getMinutes()) +
    ':' +
    pad(d.getSeconds()) +
    '.' +
    (d.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
    'Z'
  );
};

export const getSimpleDateForInputFromFullDateString = (dateValue: string) => {
  const selectedDate = new Date(dateValue);
  const formattedDate = format(selectedDate, 'yyyy-MM-dd');
  return formattedDate
}

export const getDateForInputFromFullDateString = (dateValue: string) => {
  const selectedDate = new Date(dateValue);
  const adjustedDate = addMinutes(selectedDate, selectedDate.getTimezoneOffset());
  const formattedDate = format(adjustedDate, 'yyyy-MM-dd\'T\'HH:mm');
  return formattedDate;
}

export const getDateTillFromDateAndDuration = (dateFrom: string, durationInMinutes: number) => {
  const selectedDate = new Date(dateFrom);
  const adjustedDate = addMinutes(selectedDate, durationInMinutes);
  const formattedDate = format(adjustedDate, 'yyyy-MM-dd\'T\'HH:mm');
  return formattedDate;
}

export const getDefaultDateFromSimpleDateString = (dateValue: string) => {
  const selectedDate = new Date(dateValue);
  const formattedDate = format(selectedDate, 'yyyy-MM-dd\'T\'12:00');
  return formattedDate
}

export const getDurationFromDates = (dateFrom: string, dateTill: string) => {
  const selectedDateFrom = new Date(dateFrom);
  const selectedDateTo = new Date(dateTill);
  const duration = selectedDateTo.getTime() - selectedDateFrom.getTime();
  const durationInMinutes = Math.floor(duration / 1000 / 60);

  return durationInMinutes
}

export const convertMinutesToHHmmForInput = (minutes: number) => {
  if (isNaN(minutes)) return '';
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours}:${remainingMinutes.toString().padStart(2, '0')}`;
};

export const zonedEndOfMonth = (date: Date) => {
  return DateTime
    .fromMillis(new Date(date).getTime())
    .setZone('UTC')
    .endOf('month')
};

export const zonedEndOfDay = (date: Date) => {
  return DateTime
    .fromMillis(new Date(date).getTime())
    .setZone('UTC')
    .endOf('day')
};

export const correctTimeFromInput = (input: string | number): string => {
  input = String(input).trim().replace(/[^0-9:]/g, '');

  let [hours, minutes]: number[] | string[] = input.split(':');

  const adjustTime = (time: string | number) => {
    switch (time) {
      case '':
      case undefined:
        return '00';
      default:
        if (typeof time === 'string' && time.length === 1) {
          return `0${time}`;
        } else if (typeof time === 'string' && time.length > 2) {
          return time.slice(0, 2);
        } else if (parseInt(time as string, 10) > 59) {
          return '59';
        } else if (isNaN(parseInt(time as string, 10))) {
          return '00';
        } else {
          time = parseInt(time as string, 10);
          return time < 10 ? `0${time}` : `${time}`;
        }
    }
  };

  minutes = adjustTime(minutes);
  hours = adjustTime(hours);

  return `${hours}:${minutes}`;
}
