import { RepeatIcon } from '@chakra-ui/icons';
import {
  SimpleGrid,
  Stack,
  Text,
  InputGroup,
  FormControl,
  Button,
  Select,
  IconButton,
  Spinner,
  FormLabel,
  Flex,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import useUserDataRolesStore from 'contexts/authStore';
import useFormErrorsStore from 'contexts/formErrorsStore';
import { useCompanyStore } from 'contexts/globalStoreCompanies';
import { useProjectStore } from 'contexts/globalStoreProjects';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { checkIfUserCompanyCreator } from 'utils/roleHelpers';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .typeError('emailRequired')
    .required('emailRequired'),
  companyId: yup
    .number()
    .typeError('companyRequired')
    .required('companyRequired'),
  contactPersonId: yup
    .number()
    .nullable(),
});

export const ProjectCompany = ({ viewOnly }: { viewOnly: boolean }) => {
  const singleProjectData = useProjectStore((state) => state.project);
  const setProjectObj = useProjectStore((state) => state.setProjectData);
  const companies = useCompanyStore((state) => state.companies);
  const fetchCompanies = useCompanyStore((state) => state.fetchCompanies);
  const selectedCompanyPeople = useCompanyStore((state) => state.peopleInDB);
  const fetchCompanyPeople = useCompanyStore((state) => state.fetchPeople);

  const userRoles = useUserDataRolesStore((state) => state.userRoles);
  const isUserCompanyCreator = useMemo(() => checkIfUserCompanyCreator(userRoles), [userRoles]);
  const hasUserRoleInAnyProject = useUserDataRolesStore((state) => state.hasUserRoleInAnyProject);

  const handleChange = useCallback((field: any, value: any) => {
    setProjectObj({
      [field]: value,
    });
  }, [setProjectObj]);

  const setTrue = useProjectStore((state) => state.setToTrue);

  const [isRefreshButtonLoading, setIsRefreshButtonLoading] = useState(false);

  useEffect(() => {
    fetchCompanyPeople(singleProjectData.companyId);
  }, [singleProjectData.companyId, fetchCompanyPeople]);

  // const resetErrors = useFormErrorsStore((state) => state.resetErrors);
  const errors = useFormErrorsStore((state) => state.errors);
  const setErrors = useFormErrorsStore((state) => state.setErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const deleteError = useFormErrorsStore((state) => state.deleteError);
  const deleteErrorsContainingString = useFormErrorsStore((state) => state.deleteErrorsContainingString);

  const validateFormData = useCallback(async (formData: any) => {
    try {
      await validationSchema.validate(formData);
      setTrue('companyValidation', true);
      return true;
    } catch (error) {
      setTrue('companyValidation', false);
      return false;
    }
  }, [setTrue]);

  const [eachFieldStateValidation, setEachFieldStateValidation] = useState({
    companyId: { valid: true, error: '' },
    contactPersonId: { valid: true, error: '' },
    email: { valid: true, error: '' },
  });

  useEffect(() => {
    // resetErrors();
    validateFormData(singleProjectData)
      .then((result) => {
        if (!result) {
          // For more scalable solution see ProjectInvoice
          validateSingleField('companyId', singleProjectData.companyId);
          validateSingleField('email', singleProjectData.email);
        } else {
          deleteErrorsContainingString(t('errorByCompany', { ns: ['hints'] }));
        }
      });
    // Don't add validateSingleField to deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteError, setErrors, singleProjectData, validateFormData]);

  const validateSingleField = (path: string, input: any) => {
    // `reach()` pulls out a child schema so we can test a single path
    const field: any = yup.reach(validationSchema, path);

    field
      .validate(input)
      .then(() =>
        setEachFieldStateValidation({
          ...eachFieldStateValidation,
          [path]: { valid: true, error: '' },
        }),
      ) // if valid set true
      .catch((e: any) => {
        setEachFieldStateValidation({
          ...eachFieldStateValidation,
          [path]: { valid: false, error: e.errors.join(', ') },
        });
        setErrors([...errors,
        `${t('errorByCompany', { ns: ['hints'] })}: 
            ${e.errors.map((e: any) => t(e, { ns: ['errors'] })).join(', ')}`
        ]);
      }); // if invalid set false
  };

  //TODO maybe DRY with cost centers tab
  const ReloadButton = () => (
    <IconButton
      aria-label="Reload"
      variant="brand"
      icon={isRefreshButtonLoading ? (<Spinner />) : (<RepeatIcon />)}
      ml={2}
      data-test-id={`reload-button-project-company`}
      onClick={async () => {
        setIsRefreshButtonLoading(true);
        await fetchCompanies();
        if (singleProjectData?.companyId) {
          await fetchCompanyPeople(singleProjectData?.companyId);
        }
        setIsRefreshButtonLoading(false);
      }}
    />
  );

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} spacingX="20px">
      <Stack
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
      >
        <Flex w="100%" direction="column">
          <InputField
            mb="0px"
            id="email"
            placeholder={t('email', { ns: ['labels'] })}
            label={t('email', { ns: ['labels'] }) + '*'}
            value={singleProjectData?.email || ''}
            data-test-id={`project-company-email-input`}
            onChange={(event: any) => {
              handleChange('email', event.target.value);
            }}
            onBlur={(event: any) => {
              validateSingleField('email', event.target.value);
            }}
            disabled={viewOnly}
          />
          <Text data-test-id={`project-company-email-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
            {showErrors ? t(eachFieldStateValidation['email']?.error, { ns: ['hints'] }) : null}
            &nbsp;
          </Text>
        </Flex>
      </Stack>
      {companies?.length ? (<Stack
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
        gridColumn={"1/2"}
      >
        <InputGroup>
          <FormControl>
            <Stack direction="column">
              <FormLabel
                ms="10px"
                htmlFor="projectId"
                fontSize="sm"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                {t('company', { ns: ['labels'] }) + '*'}
              </FormLabel>
              <Select
                value={singleProjectData.companyId || ""}
                disabled={viewOnly}
                data-test-id={`project-company-select`}
                onChange={(event) => {
                  handleChange('contactPersonId', null);
                  handleChange('companyId', Number(event.target.value));
                  validateSingleField('companyId', Number(event.target.value));
                }}
              >
                <option hidden disabled value="">{t('selectCompany', { ns: ['labels'] })}</option>
                {companies?.map((company: any) => {
                  return (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  );
                })}
              </Select>
              <Text data-test-id={`project-company-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
                {eachFieldStateValidation?.['companyId']?.error && showErrors
                  ?
                  t(eachFieldStateValidation['companyId'].error, { ns: ["hints"] })
                  : ''}
                &nbsp;
              </Text>
            </Stack>
          </FormControl>

          <Flex direction={'row'} alignItems={'end'} mb={'29px'}>
            <Button
              ml={2}
              mr={2}
              data-test-id={`project-company-open-button`}
              isDisabled={!singleProjectData?.companyId ||
                (!isUserCompanyCreator && !hasUserRoleInAnyProject.techAdmin && !hasUserRoleInAnyProject.finAdmin)
              }
              onClick={() => window.open(`/companies/edit/${singleProjectData?.companyId}`, '_blank')}
            >
              {t('open', { ns: ['labels'] })}
            </Button>
            <ReloadButton />
          </Flex>
        </InputGroup>
      </Stack>) : <Stack direction={'row'} alignItems={'center'}>
        <Text>{t('dontHaveCompanies', { ns: ['labels'] })}</Text>
        <ReloadButton />
      </Stack>
      }
      <Stack
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
        gridColumn={"1/2"}
      >
        {
          !singleProjectData?.companyId ? t('pleaseSelectCompany', { ns: ['labels'] }) :
            selectedCompanyPeople?.length ? (
              <InputGroup>
                <FormControl>
                  <Stack direction="column">
                    <FormLabel
                      ms="10px"
                      htmlFor="projectId"
                      fontSize="sm"
                      fontWeight="bold"
                      _hover={{ cursor: 'pointer' }}
                    >
                      {t('contactPerson', { ns: ['labels'] })}
                    </FormLabel>
                    <Select
                      value={singleProjectData?.contactPersonId || ""}
                      placeholder={t('no', { ns: ['labels'] })}
                      disabled={viewOnly}
                      data-test-id={`project-contact-person-select`}
                      onChange={(event) => {
                        handleChange('contactPersonId', Number(event.target.value));
                        validateSingleField('contactPersonId', Number(event.target.value));
                      }}
                    >
                      <option hidden disabled value="">{t('selectContact', { ns: ['labels'] })}</option>
                      {selectedCompanyPeople?.map((person: any) => {
                        return (
                          <option key={person.id} value={person.id}>
                            {person.fullname}
                          </option>
                        );
                      })}
                    </Select>
                    <Text data-test-id={`project-contact-person-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
                      {t(eachFieldStateValidation['contactPersonId'].error)}
                      &nbsp;
                    </Text>

                  </Stack>
                </FormControl>
              </InputGroup>
            ) : (<>
              <Text>{t('noContactsInCompany', { ns: ['labels'] })}</Text>
              <IconButton
                aria-label="Reload"
                variant="brand"
                icon={isRefreshButtonLoading ? (<Spinner />) : (<RepeatIcon />)}
                isDisabled={viewOnly}
                data-test-id={`project-company-reload-button`}
                onClick={async () => {
                  setIsRefreshButtonLoading(true);
                  await fetchCompanies();
                  setIsRefreshButtonLoading(false);
                }}
              />
            </>)
        }
      </Stack>
    </SimpleGrid>
  );
};
