import { Api } from 'utils/postos-api';
import { create } from 'zustand';
import { produce } from 'immer';

const api = new Api();

export interface User {
  name: string;
  email: string;
  userId: number;
  status: 'ACTIVE' | 'INACTIVE';
}

interface UsersStore {
  users: User[];
  fetchUsers: () => Promise<User[]>;
  reset: () => void;
  set: (fn: any) => void;
  sortUsersByStatusAndName: (users: User[]) => User[];
}

export const useFakturaUsersStore = create<UsersStore>((set, get) => ({
  set: (fn: any) => set(produce(fn)),
  reset: () => {
    set({
      users: [],
    });
  },
  users: [],

  fetchUsers: async (): Promise<User[]> => {
    try {
      const response: any = await api.postos.postosFetchAllUsers();
      const mappedUsers: User[] = response.data.data.body.users.map(
        (user: Omit<User, 'userId'> & { user_id: number }) => ({
          userId: user.user_id,
          name: user.name,
          email: user.email,
          status: user.status,
        }),
      );
      const sortedUsers = get().sortUsersByStatusAndName(mappedUsers);
      set(
        produce((state) => {
          state.users = sortedUsers;
        }),
      );
      return sortedUsers;
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  },
  sortUsersByStatusAndName: (users: User[]) => {
    return users.sort((a, b) => {
      if (a.status === 'ACTIVE' && b.status !== 'ACTIVE') {
        return -1;
      } else if (a.status !== 'ACTIVE' && b.status === 'ACTIVE') {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    })
  }
}));
