import { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import {
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Spacer,
  Spinner
} from '@chakra-ui/react';
import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import useLoaderStore from 'contexts/globalStore';
import { RepeatIcon } from '@chakra-ui/icons';
import { ProjectReport, useReportsStore } from 'contexts/globalStoreReports';
import { tableStorage } from 'contexts/tableStorage';
import FakturaTable from 'components/tables/FakturaTable';
import { useNavigate } from 'react-router';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE_DEFAULT } from 'variables/pagination';
import { convertMinutesToHHmmForInput } from 'utils/dateHelpers';

type RowObj = ProjectReport & {
  unit: string;
};

export default function SearchTableProjectReports() {
  const navigate = useNavigate();

  const textColor = useColorModeValue('navy.700', 'white');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);


  const isInitDone = useReportsStore((state) => state.isInitDone);
  const { fakturaProjectReports } = useReportsStore(
    (state: any) => ({ fakturaProjectReports: state.projectReports }),
  );
  const { projectReportsMeta } = useReportsStore(
    (state: any) => ({ projectReportsMeta: state.projectReportsMeta }),
  );

  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);
  const fetchProjectReports = useReportsStore((state) => state.fetchProjectReports);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: PAGE_INDEX_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
  });

  const [activeProjectsOnly, setActiveProjectsOnly] = useState<boolean>(true);

  const refreshData = useCallback(() => {
    setLoadingState(true);
    fetchProjectReports(
      activeProjectsOnly,
      pagination.pageIndex + 1,
      pagination.pageSize)
      .then(() => {
        setLoadingState(false);
      })
      .catch((error) => {
        setLoadingState(false);
        console.error('Error fetching Faktura Reports:', error);
      });
  }, [activeProjectsOnly,
    fetchProjectReports,
    pagination.pageIndex,
    pagination.pageSize,
    setLoadingState]);

  // const resetReportList = useReportsStore((state) => state.resetReportList);

  // const handleActiveProjectsToggle = (value: boolean) => {
  //   const newActiveProjectsOnlyValue = value;
  //   setActiveProjectsOnly(newActiveProjectsOnlyValue);
  // };

  useEffect(() => {
    if (isInitDone) {
      refreshData();
    }
  }, [isInitDone, refreshData]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      setData(fakturaProjectReports);
    }
    return () => {
      isCurrent = false;
    };
  }, [fakturaProjectReports]);

  const [globalFilter, setGlobalFilter] = useState('');

  const columnHelper = createColumnHelper<RowObj>();

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-project-table-header-id"
        >
          id
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="md" fontWeight="500"
          data-test-id={`reports-project-table-cell-id-${info.getValue()}`}
        >
          #{info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('title', {
      id: 'title',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-project-table-header-title"
        >
          NAME
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;

        return (
          <Flex
            alignItems={'center'}
            onClick={() => navigate(`/projects/edit/${id}`)}
            style={{ cursor: 'pointer' }}
          >
            <Text color={textColor} fontSize="md" fontWeight="500"
              data-test-id={`reports-project-table-cell-title-${id}`}
            >
              {info.getValue()}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('reference', {
      id: 'reference',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-project-table-header-reference"
        >
          {t('reference', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;

        return (
          <Flex alignItems={'center'}>
            <Text color={textColor} fontSize="md" fontWeight="500" style={{ textAlign: 'center' }}
              data-test-id={`reports-project-table-cell-reference-${id}`}
            >
              {info.getValue()}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('totalProjectAmount', {
      id: 'totalProjectAmount',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-project-table-header-totalProjectAmount"
        >
          {t('totalProjectAmount', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;

        return (
          <Flex alignItems={'center'}>
            <Text color={textColor} fontSize="md" fontWeight="500" style={{ textAlign: 'center' }}
              data-test-id={`reports-project-table-cell-totalProjectAmount-${id}`}
            >
              {convertMinutesToHHmmForInput(Number(info.getValue()))}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('totalProjectInvoicedAmount', {
      id: 'totalProjectInvoicedAmount',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-project-table-header-totalProjectInvoicedAmount"
        >
          {t('totalProjectInvoicedAmount', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;

        return (
          <Flex alignItems={'center'} justifyContent={'center'}
            data-test-id={`reports-project-table-cell-totalProjectInvoicedAmount-${id}`}
          >
            <Text
              color={textColor}
              fontSize="md"
              fontWeight="500"
              style={{ textAlign: 'center' }}
            >
              {convertMinutesToHHmmForInput(Number(info.getValue()))}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('unit', {
      id: 'unit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-project-table-header-unit"
        >
          {t('unit', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;

        return (
          <Text color={textColor} fontSize="md" fontWeight="500"
            data-test-id={`reports-project-table-cell-unit-${id}`}
          >
            {t('hours', { ns: ['labels'] })}
          </Text>
        );
      },
    }),
  ];

  const [data, setData] = useState<RowObj[]>(() => []);

  const tableName = useMemo(() => 'reports', []);

  useEffect(() => {
    const {
      globalFilter: newGlobalFilter,
      activeProjectsOnly: newActiveProjectsOnly,
    } = tableStorage.fetch(tableName)

    if (newGlobalFilter) setGlobalFilter(newGlobalFilter);
    if (newActiveProjectsOnly) setActiveProjectsOnly(newActiveProjectsOnly);
  }, [tableName])

  useEffect(() => {
    tableStorage.save(tableName, {
      globalFilter,
      activeProjectsOnly,
    })
  }, [globalFilter, tableName, activeProjectsOnly])

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination
    },
    pageCount: projectReportsMeta.totalPages,
    rowCount: projectReportsMeta.totalItems,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    manualPagination: true,
    manualSorting: false,
    enableSorting: false,
    enableMultiSort: false,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <Flex direction="column" w="100%" overflowX={{ sm: 'auto', lg: 'auto' }}>
      <Flex
        flexDirection={{ sm: 'column', lg: 'row' }}
        align={{ sm: 'flex-start', lg: 'center' }}
        justify={{ sm: 'flex-start', lg: 'space-between' }}
        w="100%"
        px="22px"
        mb="10px"
        minWidth="max-content"
        gap="4"
      >
        <SimpleGrid columns={{ base: 1, xl: 4 }} gap="5px">
          {/* <Stack direction="column" gap="20px" ml={2} justifyContent={'center'}>
            <Checkbox
              isChecked={activeProjectsOnly}
              onChange={(event: any) => {
                handleActiveProjectsToggle(event.target.checked);
              }}
            >
              {t("onlyActiveProjects", { ns: ["labels"] })}
            </Checkbox>
          </Stack> */}
        </SimpleGrid>
        {/* Only display Spacer on larger screens */}
        <Spacer display={{ sm: 'none', lg: 'block' }} />

        {loadingState ? (
          <Spinner />
        ) : (
          <IconButton
            aria-label="Reload"
            variant="brand"
            icon={<RepeatIcon />}
            data-test-id="reports-project-table-refresh-button"
            onClick={refreshData}
          >
            Reload
          </IconButton>
        )}
      </Flex>
      <FakturaTable
        table={table}
        loadingState={loadingState}
        pagination={pagination}
        setPagination={setPagination}
        data-test-id="reports-project-table"
      />
    </Flex>);
}
