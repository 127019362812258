/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** PostosBookingStatus_change */
export interface PostosBookingStatusChange {
  ids: any[];
  status: string;
}

/** PostosStatustransformationsUpdate */
export interface PostosStatustransformationsUpdate {
  /**
   * @format int32
   * @min 1
   */
  id: number;
  /**
   * @format int32
   * @min 1
   */
  from_status_id: number;
  /**
   * @format int32
   * @min 1
   */
  to_status_id: number;
}

/** PostosStatusvaluesDelete */
export interface PostosStatusvaluesDelete {
  /**
   * @format int32
   * @min 1
   */
  id: number;
}

/** Postos_invoicesBODYValidationRule */
export interface PostosInvoicesBODYValidationRule {
  /** @format int64 */
  project_id: number;
  /** @format int32 */
  invoice_number: number;
  /** @format date-time */
  date: string;
  /** @format date-time */
  date_created: string;
  is_cancelation: boolean;
  status?: string;
}

/** Postos_projectsBODYValidationRule */
export interface PostosProjectsBODYValidationRule {
  title: string;
  description?: string;
  type: string;
  /** @format double */
  vat_rate?: number;
  reverse_charge?: boolean;
  language?: string;
  currency?: string;
  timesheet_on_invoice?: boolean;
  terms_of_payment?: string;
  /** @format int32 */
  rounding?: number;
  reference?: string;
  rounding_type?: string;
  /** @format date-time */
  start_date: string;
  /** @format date-time */
  end_date?: string;
  /** @format int32 */
  amount_of_hours: number;
  /** @format int32 */
  hours_per_month?: number;
  hour_format?: string;
  /** @format int64 */
  company_id: number;
  /** @format int64 */
  contact_person_id?: number;
  /** @format int64 */
  cost_center_billable: number;
  /** @format int64 */
  cost_center_non_billable?: number;
  email: string;
  is_active: boolean;
  doc_url?: string;
  /** @format date-time */
  created_at: string;
  /** @format int32 */
  created_by: number;
  /** @format date-time */
  edited_at: string;
  /** @format int32 */
  edited_by: number;
  merge_bookings_descriptions: boolean;
  time_unit: string;
}

/** LoginByEmailAndPasswordValidation */
export interface LoginByEmailAndPasswordValidation {
  email: string;
  password: string;
}

/** Postos_projects_usersBODYValidationRule */
export interface PostosProjectsUsersBODYValidationRule {
  /** @format int64 */
  project_id: number;
  /** @format int32 */
  user_id: number;
  role: string;
  /** @format int32 */
  hours_per_month?: number;
  /** @format double */
  resource_rate?: number;
  /** @format double */
  client_rate?: number;
}

/** Postos_companiesBODYCUSTOMValidationRuleALLFIELDS */
export interface PostosCompaniesBODYCUSTOMValidationRuleALLFIELDS {
  name: string;
  registration_number?: string;
  uid?: string;
  /** @format date-time */
  created_at: string;
  /** @format int32 */
  created_by: number;
  /** @format date-time */
  edited_at: string;
  /** @format int32 */
  edited_by: number;
  /** @minItems 1 */
  addresses: PostosCompanyAddressesBODYValidationRuleForCompanyCheck[];
  /** @format double */
  status: number;
}

/** Postos_company_addressesBODYValidationRuleForCompanyCheck */
export interface PostosCompanyAddressesBODYValidationRuleForCompanyCheck {
  address_line_1: string;
  address_line_2?: string;
  zip_code: string;
  city: string;
  country: string;
  is_billing_address: boolean;
}

/** PostosStatustransformationsCreate */
export interface PostosStatustransformationsCreate {
  /**
   * @format int32
   * @min 1
   */
  from_status_id: number;
  /**
   * @format int32
   * @min 1
   */
  to_status_id: number;
  /**
   * @format int32
   * @min 1
   */
  config_id: number;
}

/** Postos_company_worklogBODYValidationRule */
export interface PostosCompanyWorklogBODYValidationRule {
  /** @format int64 */
  company_id: number;
  status: string;
  comment?: string;
  /** @format date-time */
  created_at: string;
  /** @format int32 */
  created_by: number;
  /** @format date-time */
  edited_at: string;
  /** @format int32 */
  edited_by: number;
}

/** Postos_company_statuses_localizationBODYValidationRule */
export interface PostosCompanyStatusesLocalizationBODYValidationRule {
  /** @format int64 */
  status_id: number;
  language: string;
  name_local: string;
}

/** PostosStatusconfigurationUpdate */
export interface PostosStatusconfigurationUpdate {
  /**
   * @format int32
   * @min 1
   */
  id: number;
  /**
   * @minLength 3
   * @maxLength 255
   */
  name: string;
}

/** Postos_company_addressesBODYValidationRule */
export interface PostosCompanyAddressesBODYValidationRule {
  address_line_1: string;
  address_line_2?: string;
  zip_code: string;
  city: string;
  country: string;
  /** @format int64 */
  company_id: number;
  is_billing_address: boolean;
}

/** Postos_cost_centersBODYValidationRule */
export interface PostosCostCentersBODYValidationRule {
  name: string;
  is_billable: boolean;
  description?: string;
  /** @format date-time */
  created_at: string;
  /** @format int32 */
  created_by: number;
  /** @format date-time */
  edited_at: string;
  /** @format int32 */
  edited_by: number;
}

/** Postos_company_peopleBODYValidationRule */
export interface PostosCompanyPeopleBODYValidationRule {
  fullname: string;
  contact_email?: string;
  phone_number?: string;
  /** @format int64 */
  company_id: number;
  /** @format int64 */
  address_id: number;
}

/** PostosStatusvaluesCreate */
export interface PostosStatusvaluesCreate {
  /**
   * @minLength 3
   * @maxLength 128
   */
  name: string;
  editable: boolean;
  /**
   * @format int32
   * @min 1
   */
  config_id: number;
}

/** Postos_projectsIDsArray */
export interface PostosProjectsIDsArray {
  project_ids: any[];
}

/** Postos_projects_tasksBODYValidationRule */
export interface PostosProjectsTasksBODYValidationRule {
  /** @format int64 */
  project_id: number;
  /** @format int64 */
  task_id: number;
}

/** PostosInvoiceData */
export interface PostosInvoiceData {
  /**
   * @format int32
   * @min 1
   */
  projectId: number;
  /** @format date-time */
  dateFrom: string;
  /** @format date-time */
  dateTill: string;
  invoiceType: string;
  chosenBookingIds?: any[];
}

/** PostosStatusconfigurationCreate */
export interface PostosStatusconfigurationCreate {
  /**
   * @minLength 3
   * @maxLength 128
   */
  name: string;
}

/** Postos_company_statusesBODYValidationRule */
export interface PostosCompanyStatusesBODYValidationRule {
  status: string;
}

/** Postos_tasksBODYValidationRule */
export interface PostosTasksBODYValidationRule {
  name: string;
}

/** PostosStatusconfigurationDelete */
export interface PostosStatusconfigurationDelete {
  /**
   * @format int32
   * @min 1
   */
  id: number;
}

/** PostosStatustransformationsDelete */
export interface PostosStatustransformationsDelete {
  /**
   * @format int32
   * @min 1
   */
  id: number;
}

/** PostosStatusvaluesUpdate */
export interface PostosStatusvaluesUpdate {
  /**
   * @minLength 3
   * @maxLength 255
   */
  name: string;
  editable: boolean;
}

/** PostosInvoiceStatus_change */
export interface PostosInvoiceStatusChange {
  /** @format int32 */
  id: number;
  status: string;
}

/** Postos_time_bookingsBODYValidationRule */
export interface PostosTimeBookingsBODYValidationRule {
  /** @format int64 */
  project_id: number;
  /** @format int32 */
  user_id: number;
  description: string;
  /** @format date-time */
  date_from: string;
  /** @format date-time */
  date_till: string;
  /** @format int32 */
  duration: number;
  is_entry_by_time_frame: boolean;
  status: string;
  /** @format int64 */
  task_id: number;
  is_billable: boolean;
  internal_note?: string;
  /** @format date-time */
  created_at: string;
  /** @format int32 */
  created_by: number;
  /** @format date-time */
  edited_at: string;
  /** @format int32 */
  edited_by: number;
}

export interface PostosReportsGetMonthlyParams {
  /**
   * dateFrom
   * @format date-time
   */
  dateFrom: string;
  /**
   * dateTill
   * @format date-time
   */
  dateTill: string;
}

export interface PostosReportsGetYearlyParams {
  /**
   * dateFrom
   * @format date-time
   */
  dateFrom: string;
  /**
   * dateTill
   * @format date-time
   */
  dateTill: string;
  /**
   * userId
   * @format int32
   */
  userId: number;
}

export interface PostosInvoicesTimesheetsParams {
  /**
   * invoiceId
   * @format int32
   */
  invoiceId: number;
  /** invoiceType */
  invoiceType?: string;
}

export interface PostosCompanyWorklogSearchTriggerRestParams {
  /**
   * company_id
   * @format int64
   */
  company_id: number;
  /** status */
  status?: string;
  /**
   * created_by
   * @format int32
   */
  created_by?: number;
  /**
   * edited_by
   * @format int32
   */
  edited_by?: number;
}

export interface PostosProjectsSearchTriggerRestParams {
  /** title */
  title?: string;
  /** type */
  type?: string;
  /** language */
  language?: string;
  /** currency */
  currency?: string;
  /**
   * start_dateFrom
   * @format date-time
   */
  start_dateFrom?: string;
  /**
   * start_dateTill
   * @format date-time
   */
  start_dateTill?: string;
  /**
   * end_dateFrom
   * @format date-time
   */
  end_dateFrom?: string;
  /**
   * end_dateTill
   * @format date-time
   */
  end_dateTill?: string;
  /** hour_format */
  hour_format?: string;
  /**
   * company_id
   * @format int64
   */
  company_id?: number;
  /**
   * contact_person_id
   * @format int64
   */
  contact_person_id?: number;
  /**
   * cost_center_billable
   * @format int64
   */
  cost_center_billable?: number;
  /**
   * cost_center_non_billable
   * @format int64
   */
  cost_center_non_billable?: number;
  /** is_active */
  is_active?: boolean;
  /**
   * created_atFrom
   * @format date-time
   */
  created_atFrom?: string;
  /**
   * created_atTill
   * @format date-time
   */
  created_atTill?: string;
  /**
   * created_by
   * @format int32
   */
  created_by?: number;
  /**
   * edited_atFrom
   * @format date-time
   */
  edited_atFrom?: string;
  /**
   * edited_atTill
   * @format date-time
   */
  edited_atTill?: string;
  /**
   * edited_by
   * @format int32
   */
  edited_by?: number;
  /** merge_bookings_descriptions */
  merge_bookings_descriptions?: boolean;
  /** time_unit */
  time_unit?: string;
  /**
   * size
   * @format int32
   */
  size?: number;
  /**
   * page
   * @format int32
   */
  page?: number;
  /** orderDirection */
  orderDirection?: string;
  /** orderField */
  orderField?: string;
}

export interface PostosGetUserDetailsParams {
  /**
   * userId
   * @format double
   */
  userId: number;
}

export interface PostosCostCentersDeleteTriggerRestParams {
  id: string;
}

export interface PostosTasksSearchTriggerRestParams {
  /** name */
  name?: string;
}

export interface PostosCompanyAddressesSearchTriggerRestParams {
  /**
   * company_id
   * @format int64
   */
  company_id?: number;
  /** is_billing_address */
  is_billing_address?: boolean;
}

export interface PostosCompanyStatusesLocalizationSearchTriggerRestParams {
  /**
   * status_id
   * @format int64
   */
  status_id?: number;
  /** language */
  language?: string;
  /** name_local */
  name_local?: string;
}

export interface PostosReportsGetByTimeframeParams {
  /**
   * dateFrom
   * @format date-time
   */
  dateFrom: string;
  /**
   * dateTill
   * @format date-time
   */
  dateTill: string;
  /**
   * projectId
   * @format int32
   */
  projectId?: number;
}

export interface PostosTimeBookingsSearchTriggerRestParams {
  /** project_users_ids */
  project_users_ids?: number[];
  /**
   * id
   * @format int64
   */
  id?: number;
  /**
   * project_id
   * @format int64
   */
  project_id?: number;
  /**
   * user_id
   * @format int32
   */
  user_id?: number;
  /**
   * date_fromFrom
   * @format date-time
   */
  date_fromFrom?: string;
  /**
   * date_fromTill
   * @format date-time
   */
  date_fromTill?: string;
  /**
   * date_tillFrom
   * @format date-time
   */
  date_tillFrom?: string;
  /**
   * date_tillTill
   * @format date-time
   */
  date_tillTill?: string;
  /** is_entry_by_time_frame */
  is_entry_by_time_frame?: boolean;
  /** status */
  status?: string;
  /**
   * task_id
   * @format int64
   */
  task_id?: number;
  /** is_billable */
  is_billable?: boolean;
  /** internal_note */
  internal_note?: string;
  /**
   * created_atFrom
   * @format date-time
   */
  created_atFrom?: string;
  /**
   * created_atTill
   * @format date-time
   */
  created_atTill?: string;
  /**
   * created_by
   * @format int32
   */
  created_by?: number;
  /**
   * edited_atFrom
   * @format date-time
   */
  edited_atFrom?: string;
  /**
   * edited_atTill
   * @format date-time
   */
  edited_atTill?: string;
  /**
   * edited_by
   * @format int32
   */
  edited_by?: number;
  /**
   * size
   * @format int32
   */
  size?: number;
  /**
   * page
   * @format int32
   */
  page?: number;
  /** orderDirection */
  orderDirection?: string;
  /** orderField */
  orderField?: string;
}

export interface PostosInvoicePdfParams {
  /**
   * invoiceId
   * @format int32
   */
  invoiceId: number;
  /** invoiceType */
  invoiceType?: string;
}

export interface PostosReportsGetByProjectParams {
  /** placeholder */
  placeholder?: string;
}

export interface PostosInvoiceCancelParams {
  /**
   * invoiceId
   * @format int32
   */
  invoiceId: number;
  /** invoiceType */
  invoiceType?: string;
}

export interface PostosProjectsTasksSearchTriggerRestParams {
  /**
   * project_id
   * @format int64
   */
  project_id?: number;
  /**
   * task_id
   * @format int64
   */
  task_id?: number;
}

export interface PostosCompanyStatusesSearchTriggerRestParams {
  /** status */
  status?: string;
}

export interface PostosProjectsUsersSearchTriggerRestParams {
  /** includeFinData */
  includeFinData?: boolean;
  /**
   * project_id
   * @format int64
   */
  project_id?: number;
  /**
   * user_id
   * @format int32
   */
  user_id?: number;
  /** role */
  role?: string;
}

export interface PostosReportsGetByTimeframeByResourceParams {
  /**
   * dateFrom
   * @format date-time
   */
  dateFrom: string;
  /**
   * dateTill
   * @format date-time
   */
  dateTill: string;
  /**
   * projectId
   * @format int32
   */
  projectId?: number;
}

export interface PostosInvoicesListParams {
  /**
   * dateFrom
   * @format date-time
   */
  dateFrom: string;
  /**
   * dateTill
   * @format date-time
   */
  dateTill: string;
}

export interface PostosCompanyPeopleSearchTriggerRestParams {
  /** fullname */
  fullname?: string;
  /** contact_email */
  contact_email?: string;
  /**
   * company_id
   * @format int64
   */
  company_id: number;
  /**
   * address_id
   * @format int64
   */
  address_id?: number;
}

export interface PostosCostCentersSearchTriggerRestParams {
  /** name */
  name?: string;
  /**
   * created_by
   * @format int32
   */
  created_by?: number;
  /**
   * edited_by
   * @format int32
   */
  edited_by?: number;
  /**
   * size
   * @format int32
   */
  size?: number;
  /**
   * page
   * @format int32
   */
  page?: number;
  /** orderDirection */
  orderDirection?: string;
  /** orderField */
  orderField?: string;
}

export interface PostosInvoicesSearchTriggerRestParams {
  /**
   * project_id
   * @format int64
   */
  project_id?: number;
  /**
   * invoice_number
   * @format int32
   */
  invoice_number?: number;
  /**
   * dateFrom
   * @format date-time
   */
  dateFrom?: string;
  /**
   * dateTill
   * @format date-time
   */
  dateTill?: string;
  /**
   * date_createdFrom
   * @format date-time
   */
  date_createdFrom?: string;
  /**
   * date_createdTill
   * @format date-time
   */
  date_createdTill?: string;
  /** is_cancelation */
  is_cancelation?: boolean;
  /** status */
  status?: string;
  /**
   * size
   * @format int32
   */
  size?: number;
  /**
   * page
   * @format int32
   */
  page?: number;
  /** orderDirection */
  orderDirection?: string;
  /** orderField */
  orderField?: string;
}

export interface PostosCompaniesSearchTriggerRestParams {
  /**
   * created_by
   * @format int32
   */
  created_by?: number;
  /**
   * edited_by
   * @format int32
   */
  edited_by?: number;
  /**
   * status
   * @format int64
   */
  status?: number;
  /**
   * size
   * @format int32
   */
  size?: number;
  /**
   * page
   * @format int32
   */
  page?: number;
  /** orderDirection */
  orderDirection?: string;
  /** orderField */
  orderField?: string;
}

/* eslint-disable */
/* tslint:disable */
//TODO maybe include it somehow with 1 or several imports
// to every new api file created from swagger,
// to not to copy all this
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios';
import axios from 'axios';
import auth from '../auth/index'

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    const xAuthToken = !auth.isLoggedIn() ? 'guest' : auth.getToken()
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: window.location.hostname === 'localhost' ? '' : '/api',
      headers: { ...axiosConfig.headers, 'x-auth-token': xAuthToken }
    });
    this.instance.interceptors.response.use(
      (response) => {
        if (response?.status === 401) auth.logout()

        return response
      },
      (error) => {
        console.log(error?.response)
        if (error?.response?.status === 401) auth.logout()

        return Promise.reject(error)
      })
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    /* Workaround to correctly pass array to query params for Flowy */
    let serializedParams: AxiosRequestConfig['params'] = query;

    if (query && typeof query === 'object') {
      const sp = new URLSearchParams();
      for (const [key, value] of Object.entries(query)) {
        if (Array.isArray(value)) {
          value.forEach(v => sp.append(key, v));
        } else if (value !== undefined) {
          sp.append(key, value);
        }
      }
      serializedParams = sp;
    }
    /* End of workaround */

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: serializedParams,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title postos
 * @version 2021.09
 * @baseUrl //rest.qa.m8y.ioapi
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  global = {
    /**
     * No description
     *
     * @tags generic_whoAmI
     * @name GenericWhoAmI
     * @summary generic_whoAmI
     * @request GET:/global/whoami
     */
    genericWhoAmI: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/global/whoami`,
        method: 'GET',
        ...params,
      }),
  };
  postos = {
    /**
     * No description
     *
     * @tags postos_reports:get_monthly
     * @name PostosReportsGetMonthly
     * @summary postos_reports:get_monthly
     * @request GET:/postos/reports/monthly
     */
    postosReportsGetMonthly: (query: PostosReportsGetMonthlyParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/reports/monthly`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_reports:get_yearly
     * @name PostosReportsGetYearly
     * @summary postos_reports:get_yearly
     * @request GET:/postos/reports/yearly
     */
    postosReportsGetYearly: (query: PostosReportsGetYearlyParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/reports/yearly`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:invoices:timesheets
     * @name PostosInvoicesTimesheets
     * @summary postos:invoices:timesheets
     * @request GET:/postos/invoice-timesheets
     */
    postosInvoicesTimesheets: (query: PostosInvoicesTimesheetsParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/invoice-timesheets`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:statusvalues:create
     * @name PostosStatusvaluesCreate
     * @summary postos:statusvalues:create
     * @request POST:/postos/statusvalues
     */
    postosStatusvaluesCreate: (PostosStatusvaluesCreate: PostosStatusvaluesCreate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/statusvalues`,
        method: 'POST',
        body: PostosStatusvaluesCreate,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:statusconfiguration:create
     * @name PostosStatusconfigurationCreate
     * @summary postos:statusconfiguration:create
     * @request POST:/postos/statusconfiguration
     */
    postosStatusconfigurationCreate: (
      PostosStatusconfigurationCreate: PostosStatusconfigurationCreate,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos/statusconfiguration`,
        method: 'POST',
        body: PostosStatusconfigurationCreate,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:statusconfiguration:update
     * @name PostosStatusconfigurationUpdate
     * @summary postos:statusconfiguration:update
     * @request PUT:/postos/statusconfiguration
     */
    postosStatusconfigurationUpdate: (
      PostosStatusconfigurationUpdate: PostosStatusconfigurationUpdate,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos/statusconfiguration`,
        method: 'PUT',
        body: PostosStatusconfigurationUpdate,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:invoice:data
     * @name PostosInvoiceData
     * @summary postos:invoice:data
     * @request POST:/postos/invoice-data
     */
    postosInvoiceData: (PostosInvoiceData: PostosInvoiceData, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/invoice-data`,
        method: 'POST',
        body: PostosInvoiceData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_tasks: SEARCH trigger rest
     * @name PostosTasksSearchTriggerRest
     * @summary postos_tasks: SEARCH trigger rest
     * @request GET:/postos/tasks
     */
    postosTasksSearchTriggerRest: (query: PostosTasksSearchTriggerRestParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/tasks`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_tasks: CREATE trigger rest
     * @name PostosTasksCreateTriggerRest
     * @summary postos_tasks: CREATE trigger rest
     * @request POST:/postos/tasks
     */
    postosTasksCreateTriggerRest: (
      Postos_tasksBODYValidationRule: PostosTasksBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos/tasks`,
        method: 'POST',
        body: Postos_tasksBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:statustransformations:update
     * @name PostosStatustransformationsUpdate
     * @summary postos:statustransformations:update
     * @request PUT:postos/statustransformations
     */
    postosStatustransformationsUpdate: (
      PostosStatustransformationsUpdate: PostosStatustransformationsUpdate,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `postos/statustransformations`,
        method: 'PUT',
        body: PostosStatustransformationsUpdate,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:statustransformations:delete
     * @name PostosStatustransformationsDelete
     * @summary postos:statustransformations:delete
     * @request DELETE:postos/statustransformations
     */
    postosStatustransformationsDelete: (
      PostosStatustransformationsDelete: PostosStatustransformationsDelete,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `postos/statustransformations`,
        method: 'DELETE',
        body: PostosStatustransformationsDelete,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:statusvalues:update
     * @name PostosStatusvaluesUpdate
     * @summary postos:statusvalues:update
     * @request PUT:postos/statusvalues
     */
    postosStatusvaluesUpdate: (PostosStatusvaluesUpdate: PostosStatusvaluesUpdate, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `postos/statusvalues`,
        method: 'PUT',
        body: PostosStatusvaluesUpdate,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:statusvalues:delete
     * @name PostosStatusvaluesDelete
     * @summary postos:statusvalues:delete
     * @request DELETE:postos/statusvalues
     */
    postosStatusvaluesDelete: (PostosStatusvaluesDelete: PostosStatusvaluesDelete, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `postos/statusvalues`,
        method: 'DELETE',
        body: PostosStatusvaluesDelete,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_reports:get_by_timeframe
     * @name PostosReportsGetByTimeframe
     * @summary postos_reports:get_by_timeframe
     * @request GET:/postos/reports/timeframe
     */
    postosReportsGetByTimeframe: (query: PostosReportsGetByTimeframeParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/reports/timeframe`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_tasks: FIND_BY_ID trigger rest
     * @name PostosTasksFindByIdTriggerRest
     * @summary postos_tasks: FIND_BY_ID trigger rest
     * @request GET:/postos/tasks/{id}
     */
    postosTasksFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/tasks/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_tasks: UPDATE trigger rest
     * @name PostosTasksUpdateTriggerRest
     * @summary postos_tasks: UPDATE trigger rest
     * @request PUT:/postos/tasks/{id}
     */
    postosTasksUpdateTriggerRest: (
      id: number,
      Postos_tasksBODYValidationRule: PostosTasksBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos/tasks/${id}`,
        method: 'PUT',
        body: Postos_tasksBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_tasks: DELETE trigger rest
     * @name PostosTasksDeleteTriggerRest
     * @summary postos_tasks: DELETE trigger rest
     * @request DELETE:/postos/tasks/{id}
     */
    postosTasksDeleteTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/tasks/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:invoice:status_change
     * @name PostosInvoiceStatusChange
     * @summary postos:invoice:status_change
     * @request POST:/postos/invoice/status-change
     */
    postosInvoiceStatusChange: (PostosInvoiceStatus_change: PostosInvoiceStatusChange, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/invoice/status-change`,
        method: 'POST',
        body: PostosInvoiceStatus_change,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:invoice:pdf
     * @name PostosInvoicePdf
     * @summary postos:invoice:pdf
     * @request GET:/postos/invoice-pdf
     */
    postosInvoicePdf: (query: PostosInvoicePdfParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/invoice-pdf`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_reports:get_by_project
     * @name PostosReportsGetByProject
     * @summary postos_reports:get_by_project
     * @request GET:/postos/reports/projects
     */
    postosReportsGetByProject: (query: PostosReportsGetByProjectParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/reports/projects`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_time_bookings:status_change
     * @name PostosTimeBookingsStatusChange
     * @summary postos_time_bookings:status_change
     * @request POST:/postos/time_bookings/status-change
     */
    postosTimeBookingsStatusChange: (
      PostosBookingStatus_change: PostosBookingStatusChange,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos/time_bookings/status-change`,
        method: 'POST',
        body: PostosBookingStatus_change,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:fetch all users
     * @name PostosFetchAllUsers
     * @summary postos:fetch all users
     * @request GET:/postos/users-all
     */
    postosFetchAllUsers: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/users-all`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:invoice:cancel
     * @name PostosInvoiceCancel
     * @summary postos:invoice:cancel
     * @request GET:/postos/invoice-cancel
     */
    postosInvoiceCancel: (query: PostosInvoiceCancelParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/invoice-cancel`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:statustransformations:create
     * @name PostosStatustransformationsCreate
     * @summary postos:statustransformations:create
     * @request POST:/postos/statustransformations
     */
    postosStatustransformationsCreate: (
      PostosStatustransformationsCreate: PostosStatustransformationsCreate,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos/statustransformations`,
        method: 'POST',
        body: PostosStatustransformationsCreate,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:statusconfiguration:delete
     * @name PostosStatusconfigurationDelete
     * @summary postos:statusconfiguration:delete
     * @request DELETE:postos/statusconfiguration
     */
    postosStatusconfigurationDelete: (
      PostosStatusconfigurationDelete: PostosStatusconfigurationDelete,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `postos/statusconfiguration`,
        method: 'DELETE',
        body: PostosStatusconfigurationDelete,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:invoice:toggle_is_payed
     * @name PostosInvoiceToggleIsPayed
     * @summary postos:invoice:toggle_is_payed
     * @request POST:/postos/invoice/toggle-is-payed/{id}
     */
    postosInvoiceToggleIsPayed: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/invoice/toggle-is-payed/${id}`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:timesheets:data
     * @name PostosTimesheetsData
     * @summary postos:timesheets:data
     * @request POST:/postos/timesheet-data
     */
    postosTimesheetsData: (PostosInvoiceData: PostosInvoiceData, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/timesheet-data`,
        method: 'POST',
        body: PostosInvoiceData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_reports:get_by_timeframe_by_resource
     * @name PostosReportsGetByTimeframeByResource
     * @summary postos_reports:get_by_timeframe_by_resource
     * @request GET:/postos/reports/timeframe_by_resource
     */
    postosReportsGetByTimeframeByResource: (
      query: PostosReportsGetByTimeframeByResourceParams,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos/reports/timeframe_by_resource`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos:invoices:list
     * @name PostosInvoicesList
     * @summary postos:invoices:list
     * @request GET:/postos/invoices
     */
    postosInvoicesList: (query: PostosInvoicesListParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos/invoices`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
  postosProjectsTasks = {
    /**
     * No description
     *
     * @tags postos_projects_tasks: FIND_BY_ID trigger rest
     * @name PostosProjectsTasksFindByIdTriggerRest
     * @summary postos_projects_tasks: FIND_BY_ID trigger rest
     * @request GET:/postos_projects_tasks/{id}
     */
    postosProjectsTasksFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_projects_tasks/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_projects_tasks: UPDATE trigger rest
     * @name PostosProjectsTasksUpdateTriggerRest
     * @summary postos_projects_tasks: UPDATE trigger rest
     * @request PUT:/postos_projects_tasks/{id}
     */
    postosProjectsTasksUpdateTriggerRest: (
      id: number,
      Postos_projects_tasksBODYValidationRule: PostosProjectsTasksBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_projects_tasks/${id}`,
        method: 'PUT',
        body: Postos_projects_tasksBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_projects_tasks: DELETE trigger rest
     * @name PostosProjectsTasksDeleteTriggerRest
     * @summary postos_projects_tasks: DELETE trigger rest
     * @request DELETE:/postos_projects_tasks/{id}
     */
    postosProjectsTasksDeleteTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_projects_tasks/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_projects_tasks: SEARCH trigger rest
     * @name PostosProjectsTasksSearchTriggerRest
     * @summary postos_projects_tasks: SEARCH trigger rest
     * @request GET:/postos_projects_tasks
     */
    postosProjectsTasksSearchTriggerRest: (
      query: PostosProjectsTasksSearchTriggerRestParams,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_projects_tasks`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_projects_tasks: CREATE trigger rest
     * @name PostosProjectsTasksCreateTriggerRest
     * @summary postos_projects_tasks: CREATE trigger rest
     * @request POST:/postos_projects_tasks
     */
    postosProjectsTasksCreateTriggerRest: (
      Postos_projects_tasksBODYValidationRule: PostosProjectsTasksBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_projects_tasks`,
        method: 'POST',
        body: Postos_projects_tasksBODYValidationRule,
        ...params,
      }),
  };
  postosCompanyAddresses = {
    /**
     * No description
     *
     * @tags postos_company_addresses: FIND_BY_ID trigger rest
     * @name PostosCompanyAddressesFindByIdTriggerRest
     * @summary postos_company_addresses: FIND_BY_ID trigger rest
     * @request GET:/postos_company_addresses/{id}
     */
    postosCompanyAddressesFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_company_addresses/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_addresses: UPDATE trigger rest
     * @name PostosCompanyAddressesUpdateTriggerRest
     * @summary postos_company_addresses: UPDATE trigger rest
     * @request PUT:/postos_company_addresses/{id}
     */
    postosCompanyAddressesUpdateTriggerRest: (
      id: number,
      Postos_company_addressesBODYValidationRule: PostosCompanyAddressesBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_addresses/${id}`,
        method: 'PUT',
        body: Postos_company_addressesBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_addresses: DELETE trigger rest
     * @name PostosCompanyAddressesDeleteTriggerRest
     * @summary postos_company_addresses: DELETE trigger rest
     * @request DELETE:/postos_company_addresses/{id}
     */
    postosCompanyAddressesDeleteTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_company_addresses/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_addresses: SEARCH trigger rest
     * @name PostosCompanyAddressesSearchTriggerRest
     * @summary postos_company_addresses: SEARCH trigger rest
     * @request GET:/postos_company_addresses
     */
    postosCompanyAddressesSearchTriggerRest: (
      query: PostosCompanyAddressesSearchTriggerRestParams,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_addresses`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_addresses: CREATE trigger rest
     * @name PostosCompanyAddressesCreateTriggerRest
     * @summary postos_company_addresses: CREATE trigger rest
     * @request POST:/postos_company_addresses
     */
    postosCompanyAddressesCreateTriggerRest: (
      Postos_company_addressesBODYValidationRule: PostosCompanyAddressesBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_addresses`,
        method: 'POST',
        body: Postos_company_addressesBODYValidationRule,
        ...params,
      }),
  };
  postosCompanyWorklog = {
    /**
     * No description
     *
     * @tags postos_company_worklog: SEARCH trigger rest
     * @name PostosCompanyWorklogSearchTriggerRest
     * @summary postos_company_worklog: SEARCH trigger rest
     * @request GET:/postos_company_worklog
     */
    postosCompanyWorklogSearchTriggerRest: (
      query: PostosCompanyWorklogSearchTriggerRestParams,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_worklog`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_worklog: CREATE trigger rest
     * @name PostosCompanyWorklogCreateTriggerRest
     * @summary postos_company_worklog: CREATE trigger rest
     * @request POST:/postos_company_worklog
     */
    postosCompanyWorklogCreateTriggerRest: (
      Postos_company_worklogBODYValidationRule: PostosCompanyWorklogBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_worklog`,
        method: 'POST',
        body: Postos_company_worklogBODYValidationRule,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_worklog: FIND_BY_ID trigger rest
     * @name PostosCompanyWorklogFindByIdTriggerRest
     * @summary postos_company_worklog: FIND_BY_ID trigger rest
     * @request GET:/postos_company_worklog/{id}
     */
    postosCompanyWorklogFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_company_worklog/${id}`,
        method: 'GET',
        ...params,
      }),
  };
  postosProjects = {
    /**
     * No description
     *
     * @tags postos_projects: SEARCH trigger rest
     * @name PostosProjectsSearchTriggerRest
     * @summary postos_projects: SEARCH trigger rest
     * @request GET:/postos_projects
     */
    postosProjectsSearchTriggerRest: (query: PostosProjectsSearchTriggerRestParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_projects`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_projects: CREATE trigger rest
     * @name PostosProjectsCreateTriggerRest
     * @summary postos_projects: CREATE trigger rest
     * @request POST:/postos_projects
     */
    postosProjectsCreateTriggerRest: (
      Postos_projectsBODYValidationRule: PostosProjectsBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_projects`,
        method: 'POST',
        body: Postos_projectsBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_projects: FIND_BY_ID trigger rest
     * @name PostosProjectsFindByIdTriggerRest
     * @summary postos_projects: FIND_BY_ID trigger rest
     * @request GET:/postos_projects/{id}
     */
    postosProjectsFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_projects/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_projects: UPDATE trigger rest
     * @name PostosProjectsUpdateTriggerRest
     * @summary postos_projects: UPDATE trigger rest
     * @request PUT:/postos_projects/{id}
     */
    postosProjectsUpdateTriggerRest: (
      id: number,
      Postos_projectsBODYValidationRule: PostosProjectsBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_projects/${id}`,
        method: 'PUT',
        body: Postos_projectsBODYValidationRule,
        ...params,
      }),
  };
  postosInvoices = {
    /**
     * No description
     *
     * @tags postos_invoices: FIND_BY_ID trigger rest
     * @name PostosInvoicesFindByIdTriggerRest
     * @summary postos_invoices: FIND_BY_ID trigger rest
     * @request GET:/postos_invoices/{id}
     */
    postosInvoicesFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_invoices/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_invoices: UPDATE trigger rest
     * @name PostosInvoicesUpdateTriggerRest
     * @summary postos_invoices: UPDATE trigger rest
     * @request PUT:/postos_invoices/{id}
     */
    postosInvoicesUpdateTriggerRest: (
      id: number,
      Postos_invoicesBODYValidationRule: PostosInvoicesBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_invoices/${id}`,
        method: 'PUT',
        body: Postos_invoicesBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_invoices: DELETE trigger rest
     * @name PostosInvoicesDeleteTriggerRest
     * @summary postos_invoices: DELETE trigger rest
     * @request DELETE:/postos_invoices/{id}
     */
    postosInvoicesDeleteTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_invoices/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_invoices: SEARCH trigger rest
     * @name PostosInvoicesSearchTriggerRest
     * @summary postos_invoices: SEARCH trigger rest
     * @request GET:/postos_invoices
     */
    postosInvoicesSearchTriggerRest: (query: PostosInvoicesSearchTriggerRestParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_invoices`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_invoices: CREATE trigger rest
     * @name PostosInvoicesCreateTriggerRest
     * @summary postos_invoices: CREATE trigger rest
     * @request POST:/postos_invoices
     */
    postosInvoicesCreateTriggerRest: (
      Postos_invoicesBODYValidationRule: PostosInvoicesBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_invoices`,
        method: 'POST',
        body: Postos_invoicesBODYValidationRule,
        ...params,
      }),
  };
  getUserDetails = {
    /**
     * No description
     *
     * @tags postos:get_user_details
     * @name PostosGetUserDetails
     * @summary postos:get_user_details
     * @request GET:/get_user_details
     */
    postosGetUserDetails: (query: PostosGetUserDetailsParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/get_user_details`,
        method: 'GET',
        query: query,
        ...params,
      }),
  };
  postosInvoiceItems = {
    /**
     * No description
     *
     * @tags postos_invoice_items: SEARCH trigger rest
     * @name PostosInvoiceItemsSearchTriggerRest
     * @summary postos_invoice_items: SEARCH trigger rest
     * @request GET:/postos_invoice_items
     */
    postosInvoiceItemsSearchTriggerRest: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_invoice_items`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_invoice_items: CREATE trigger rest
     * @name PostosInvoiceItemsCreateTriggerRest
     * @summary postos_invoice_items: CREATE trigger rest
     * @request POST:/postos_invoice_items
     */
    postosInvoiceItemsCreateTriggerRest: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_invoice_items`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_invoice_items: FIND_BY_ID trigger rest
     * @name PostosInvoiceItemsFindByIdTriggerRest
     * @summary postos_invoice_items: FIND_BY_ID trigger rest
     * @request GET:/postos_invoice_items/{id}
     */
    postosInvoiceItemsFindByIdTriggerRest: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_invoice_items/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_invoice_items: UPDATE trigger rest
     * @name PostosInvoiceItemsUpdateTriggerRest
     * @summary postos_invoice_items: UPDATE trigger rest
     * @request PUT:/postos_invoice_items/{id}
     */
    postosInvoiceItemsUpdateTriggerRest: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_invoice_items/${id}`,
        method: 'PUT',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_invoice_items: DELETE trigger rest
     * @name PostosInvoiceItemsDeleteTriggerRest
     * @summary postos_invoice_items: DELETE trigger rest
     * @request DELETE:/postos_invoice_items/{id}
     */
    postosInvoiceItemsDeleteTriggerRest: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_invoice_items/${id}`,
        method: 'DELETE',
        ...params,
      }),
  };
  postosCostCenters = {
    /**
     * No description
     *
     * @tags postos_cost_centers: FIND_BY_ID trigger rest
     * @name PostosCostCentersFindByIdTriggerRest
     * @summary postos_cost_centers: FIND_BY_ID trigger rest
     * @request GET:/postos_cost_centers/{id}
     */
    postosCostCentersFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_cost_centers/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_cost_centers: UPDATE trigger rest
     * @name PostosCostCentersUpdateTriggerRest
     * @summary postos_cost_centers: UPDATE trigger rest
     * @request PUT:/postos_cost_centers/{id}
     */
    postosCostCentersUpdateTriggerRest: (
      id: number,
      Postos_cost_centersBODYValidationRule: PostosCostCentersBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_cost_centers/${id}`,
        method: 'PUT',
        body: Postos_cost_centersBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_cost_centers: DELETE trigger rest
     * @name PostosCostCentersDeleteTriggerRest
     * @summary postos_cost_centers: DELETE trigger rest
     * @request DELETE:/postos_cost_centers/{id}
     */
    postosCostCentersDeleteTriggerRest: (
      { id, ...query }: PostosCostCentersDeleteTriggerRestParams,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_cost_centers/${id}`,
        method: 'DELETE',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_cost_centers: SEARCH trigger rest
     * @name PostosCostCentersSearchTriggerRest
     * @summary postos_cost_centers: SEARCH trigger rest
     * @request GET:/postos_cost_centers
     */
    postosCostCentersSearchTriggerRest: (query: PostosCostCentersSearchTriggerRestParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_cost_centers`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_cost_centers: CREATE trigger rest
     * @name PostosCostCentersCreateTriggerRest
     * @summary postos_cost_centers: CREATE trigger rest
     * @request POST:/postos_cost_centers
     */
    postosCostCentersCreateTriggerRest: (
      Postos_cost_centersBODYValidationRule: PostosCostCentersBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_cost_centers`,
        method: 'POST',
        body: Postos_cost_centersBODYValidationRule,
        ...params,
      }),
  };
  postosCompanyPeople = {
    /**
     * No description
     *
     * @tags postos_company_people: FIND_BY_ID trigger rest
     * @name PostosCompanyPeopleFindByIdTriggerRest
     * @summary postos_company_people: FIND_BY_ID trigger rest
     * @request GET:/postos_company_people/{id}
     */
    postosCompanyPeopleFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_company_people/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_people: UPDATE trigger rest
     * @name PostosCompanyPeopleUpdateTriggerRest
     * @summary postos_company_people: UPDATE trigger rest
     * @request PUT:/postos_company_people/{id}
     */
    postosCompanyPeopleUpdateTriggerRest: (
      id: number,
      Postos_company_peopleBODYValidationRule: PostosCompanyPeopleBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_people/${id}`,
        method: 'PUT',
        body: Postos_company_peopleBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_people: DELETE trigger rest
     * @name PostosCompanyPeopleDeleteTriggerRest
     * @summary postos_company_people: DELETE trigger rest
     * @request DELETE:/postos_company_people/{id}
     */
    postosCompanyPeopleDeleteTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_company_people/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_people: SEARCH trigger rest
     * @name PostosCompanyPeopleSearchTriggerRest
     * @summary postos_company_people: SEARCH trigger rest
     * @request GET:/postos_company_people
     */
    postosCompanyPeopleSearchTriggerRest: (
      query: PostosCompanyPeopleSearchTriggerRestParams,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_people`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_people: CREATE trigger rest
     * @name PostosCompanyPeopleCreateTriggerRest
     * @summary postos_company_people: CREATE trigger rest
     * @request POST:/postos_company_people
     */
    postosCompanyPeopleCreateTriggerRest: (
      Postos_company_peopleBODYValidationRule: PostosCompanyPeopleBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_people`,
        method: 'POST',
        body: Postos_company_peopleBODYValidationRule,
        ...params,
      }),
  };
  postosCompanyStatusesLocalization = {
    /**
     * No description
     *
     * @tags postos_company_statuses_localization: SEARCH trigger rest
     * @name PostosCompanyStatusesLocalizationSearchTriggerRest
     * @summary postos_company_statuses_localization: SEARCH trigger rest
     * @request GET:/postos_company_statuses_localization
     */
    postosCompanyStatusesLocalizationSearchTriggerRest: (
      query: PostosCompanyStatusesLocalizationSearchTriggerRestParams,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_statuses_localization`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_statuses_localization: CREATE trigger rest
     * @name PostosCompanyStatusesLocalizationCreateTriggerRest
     * @summary postos_company_statuses_localization: CREATE trigger rest
     * @request POST:/postos_company_statuses_localization
     */
    postosCompanyStatusesLocalizationCreateTriggerRest: (
      Postos_company_statuses_localizationBODYValidationRule: PostosCompanyStatusesLocalizationBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_statuses_localization`,
        method: 'POST',
        body: Postos_company_statuses_localizationBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_statuses_localization: FIND_BY_ID trigger rest
     * @name PostosCompanyStatusesLocalizationFindByIdTriggerRest
     * @summary postos_company_statuses_localization: FIND_BY_ID trigger rest
     * @request GET:/postos_company_statuses_localization/{id}
     */
    postosCompanyStatusesLocalizationFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_company_statuses_localization/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_statuses_localization: UPDATE trigger rest
     * @name PostosCompanyStatusesLocalizationUpdateTriggerRest
     * @summary postos_company_statuses_localization: UPDATE trigger rest
     * @request PUT:/postos_company_statuses_localization/{id}
     */
    postosCompanyStatusesLocalizationUpdateTriggerRest: (
      id: number,
      Postos_company_statuses_localizationBODYValidationRule: PostosCompanyStatusesLocalizationBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_statuses_localization/${id}`,
        method: 'PUT',
        body: Postos_company_statuses_localizationBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_statuses_localization: DELETE trigger rest
     * @name PostosCompanyStatusesLocalizationDeleteTriggerRest
     * @summary postos_company_statuses_localization: DELETE trigger rest
     * @request DELETE:/postos_company_statuses_localization/{id}
     */
    postosCompanyStatusesLocalizationDeleteTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_company_statuses_localization/${id}`,
        method: 'DELETE',
        ...params,
      }),
  };
  postosTimeBookings = {
    /**
     * No description
     *
     * @tags postos_time_bookings: SEARCH trigger rest
     * @name PostosTimeBookingsSearchTriggerRest
     * @summary postos_time_bookings: SEARCH trigger rest
     * @request GET:/postos_time_bookings
     */
    postosTimeBookingsSearchTriggerRest: (
      query: PostosTimeBookingsSearchTriggerRestParams,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_time_bookings`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_time_bookings: CREATE trigger rest
     * @name PostosTimeBookingsCreateTriggerRest
     * @summary postos_time_bookings: CREATE trigger rest
     * @request POST:/postos_time_bookings
     */
    postosTimeBookingsCreateTriggerRest: (
      Postos_time_bookingsBODYValidationRule: PostosTimeBookingsBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_time_bookings`,
        method: 'POST',
        body: Postos_time_bookingsBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_time_bookings: FIND_BY_ID trigger rest
     * @name PostosTimeBookingsFindByIdTriggerRest
     * @summary postos_time_bookings: FIND_BY_ID trigger rest
     * @request GET:/postos_time_bookings/{id}
     */
    postosTimeBookingsFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_time_bookings/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_time_bookings: UPDATE trigger rest
     * @name PostosTimeBookingsUpdateTriggerRest
     * @summary postos_time_bookings: UPDATE trigger rest
     * @request PUT:/postos_time_bookings/{id}
     */
    postosTimeBookingsUpdateTriggerRest: (
      id: number,
      Postos_time_bookingsBODYValidationRule: PostosTimeBookingsBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_time_bookings/${id}`,
        method: 'PUT',
        body: Postos_time_bookingsBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_time_bookings: DELETE trigger rest
     * @name PostosTimeBookingsDeleteTriggerRest
     * @summary postos_time_bookings: DELETE trigger rest
     * @request DELETE:/postos_time_bookings/{id}
     */
    postosTimeBookingsDeleteTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_time_bookings/${id}`,
        method: 'DELETE',
        ...params,
      }),
  };
  postosCompanies = {
    /**
     * No description
     *
     * @tags postos_companies: FIND_BY_ID trigger rest
     * @name PostosCompaniesFindByIdTriggerRest
     * @summary postos_companies: FIND_BY_ID trigger rest
     * @request GET:/postos_companies/{id}
     */
    postosCompaniesFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_companies/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_companies: UPDATE trigger rest
     * @name PostosCompaniesUpdateTriggerRest
     * @summary postos_companies: UPDATE trigger rest
     * @request PUT:/postos_companies/{id}
     */
    postosCompaniesUpdateTriggerRest: (
      id: number,
      Postos_companiesBODYCUSTOMValidationRuleALLFIELDS: PostosCompaniesBODYCUSTOMValidationRuleALLFIELDS,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_companies/${id}`,
        method: 'PUT',
        body: Postos_companiesBODYCUSTOMValidationRuleALLFIELDS,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_companies: DELETE trigger rest
     * @name PostosCompaniesDeleteTriggerRest
     * @summary postos_companies: DELETE trigger rest
     * @request DELETE:/postos_companies/{id}
     */
    postosCompaniesDeleteTriggerRest: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_companies/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_companies: SEARCH trigger rest
     * @name PostosCompaniesSearchTriggerRest
     * @summary postos_companies: SEARCH trigger rest
     * @request GET:/postos_companies
     */
    postosCompaniesSearchTriggerRest: (query: PostosCompaniesSearchTriggerRestParams, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_companies`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_companies: CREATE trigger rest
     * @name PostosCompaniesCreateTriggerRest
     * @summary postos_companies: CREATE trigger rest
     * @request POST:/postos_companies
     */
    postosCompaniesCreateTriggerRest: (
      Postos_companiesBODYCUSTOMValidationRuleALLFIELDS: PostosCompaniesBODYCUSTOMValidationRuleALLFIELDS,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_companies`,
        method: 'POST',
        body: Postos_companiesBODYCUSTOMValidationRuleALLFIELDS,
        ...params,
      }),
  };
  projectUsers = {
    /**
     * No description
     *
     * @tags postos:get_project_user
     * @name PostosGetProjectUser
     * @summary postos:get_project_user
     * @request GET:/project_users/get_project_user
     */
    postosGetProjectUser: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/project_users/get_project_user`,
        method: 'GET',
        ...params,
      }),
  };
  faktura = {
    /**
     * No description
     *
     * @tags postos:whoAmI
     * @name PostosWhoAmI
     * @summary postos:whoAmI
     * @request GET:/faktura/whoami
     */
    postosWhoAmI: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/faktura/whoami`,
        method: 'GET',
        ...params,
      }),
  };
  loginByPassword = {
    /**
     * No description
     *
     * @tags login by password
     * @name LoginByPassword
     * @summary login by password
     * @request POST:/login-by-password
     */
    loginByPassword: (
      LoginByEmailAndPasswordValidation: LoginByEmailAndPasswordValidation,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/login-by-password`,
        method: 'POST',
        body: LoginByEmailAndPasswordValidation,
        type: ContentType.Json,
        ...params,
      }),
  };
  postosProjectsUsers = {
    /**
     * No description
     *
     * @tags postos_projects_users: FIND_BY_ID trigger rest
     * @name PostosProjectsUsersFindByIdTriggerRest
     * @summary postos_projects_users: FIND_BY_ID trigger rest
     * @request GET:/postos_projects_users/{id}
     */
    postosProjectsUsersFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_projects_users/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_projects_users: UPDATE trigger rest
     * @name PostosProjectsUsersUpdateTriggerRest
     * @summary postos_projects_users: UPDATE trigger rest
     * @request PUT:/postos_projects_users/{id}
     */
    postosProjectsUsersUpdateTriggerRest: (
      id: number,
      Postos_projects_usersBODYValidationRule: PostosProjectsUsersBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_projects_users/${id}`,
        method: 'PUT',
        body: Postos_projects_usersBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_projects_users: DELETE trigger rest
     * @name PostosProjectsUsersDeleteTriggerRest
     * @summary postos_projects_users: DELETE trigger rest
     * @request DELETE:/postos_projects_users/{id}
     */
    postosProjectsUsersDeleteTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_projects_users/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_projects_users: SEARCH trigger rest
     * @name PostosProjectsUsersSearchTriggerRest
     * @summary postos_projects_users: SEARCH trigger rest
     * @request GET:/postos_projects_users
     */
    postosProjectsUsersSearchTriggerRest: (
      query: PostosProjectsUsersSearchTriggerRestParams,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_projects_users`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_projects_users: CREATE trigger rest
     * @name PostosProjectsUsersCreateTriggerRest
     * @summary postos_projects_users: CREATE trigger rest
     * @request POST:/postos_projects_users
     */
    postosProjectsUsersCreateTriggerRest: (
      Postos_projects_usersBODYValidationRule: PostosProjectsUsersBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_projects_users`,
        method: 'POST',
        body: Postos_projects_usersBODYValidationRule,
        ...params,
      }),
  };
  postosCompanyStatuses = {
    /**
     * No description
     *
     * @tags postos_company_statuses: FIND_BY_ID trigger rest
     * @name PostosCompanyStatusesFindByIdTriggerRest
     * @summary postos_company_statuses: FIND_BY_ID trigger rest
     * @request GET:/postos_company_statuses/{id}
     */
    postosCompanyStatusesFindByIdTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_company_statuses/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_statuses: UPDATE trigger rest
     * @name PostosCompanyStatusesUpdateTriggerRest
     * @summary postos_company_statuses: UPDATE trigger rest
     * @request PUT:/postos_company_statuses/{id}
     */
    postosCompanyStatusesUpdateTriggerRest: (
      id: number,
      Postos_company_statusesBODYValidationRule: PostosCompanyStatusesBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_statuses/${id}`,
        method: 'PUT',
        body: Postos_company_statusesBODYValidationRule,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_statuses: DELETE trigger rest
     * @name PostosCompanyStatusesDeleteTriggerRest
     * @summary postos_company_statuses: DELETE trigger rest
     * @request DELETE:/postos_company_statuses/{id}
     */
    postosCompanyStatusesDeleteTriggerRest: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/postos_company_statuses/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_statuses: SEARCH trigger rest
     * @name PostosCompanyStatusesSearchTriggerRest
     * @summary postos_company_statuses: SEARCH trigger rest
     * @request GET:/postos_company_statuses
     */
    postosCompanyStatusesSearchTriggerRest: (
      query: PostosCompanyStatusesSearchTriggerRestParams,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_statuses`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags postos_company_statuses: CREATE trigger rest
     * @name PostosCompanyStatusesCreateTriggerRest
     * @summary postos_company_statuses: CREATE trigger rest
     * @request POST:/postos_company_statuses
     */
    postosCompanyStatusesCreateTriggerRest: (
      Postos_company_statusesBODYValidationRule: PostosCompanyStatusesBODYValidationRule,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/postos_company_statuses`,
        method: 'POST',
        body: Postos_company_statusesBODYValidationRule,
        ...params,
      }),
  };
  projects = {
    /**
     * No description
     *
     * @tags postos:project:check_if_invoice_data_exists
     * @name PostosProjectCheckIfInvoiceDataExists
     * @summary postos:project:check_if_invoice_data_exists
     * @request POST:/projects/check-invoice-data
     */
    postosProjectCheckIfInvoiceDataExists: (
      Postos_projectsIDsArray: PostosProjectsIDsArray,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/projects/check-invoice-data`,
        method: 'POST',
        body: Postos_projectsIDsArray,
        type: ContentType.Json,
        ...params,
      }),
  };
}
