import {
  Flex,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableReports from './components/SearchTableReports';
import SearchTableMonthlyReport from './components/SearchTableMonthlyReport';
import SearchTableByResource from './components/SearchTableByResource';
import SearchTableTotalsByResource from './components/SearchTableTotalsByResource';
import { t } from 'i18next';
import { useReportsStore } from "../../../contexts/globalStoreReports";
import { useEffect, useMemo, useState } from "react";
import { useProjectStore } from "../../../contexts/globalStoreProjects";
import useUserDataRolesStore from 'contexts/authStore';
import useLoaderStore from 'contexts/globalStore';
import SearchTableProjectReports from './components/SearchTableProjectReports';
import { useBookingStore } from 'contexts/globalStoreTimeBookings';

export default function Reports() {
  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);

  const fetchProjects = useProjectStore((state) => state.fetchProjects);
  const projects = useProjectStore((state) => state.projects);

  const set = useReportsStore((state) => state.set);

  const userData = useUserDataRolesStore((state) => state.userData);
  const userProjectsIdsByRole = useUserDataRolesStore((state) => state.userProjectsIdsByRole);

  useEffect(() => {
    let isCurrent = true;
    setLoadingState(true);

    const fetchData = async () => {
      await fetchProjects(null, null, null, null, null, null, true);


      set((state: any) => {
        state.isInitDone = true
      })
    };
    if (isCurrent) {
      fetchData().then(() => setLoadingState(false));
    }

    return () => {
      isCurrent = false;
    };
  }, [userData, fetchProjects, set, setLoadingState, userProjectsIdsByRole.techAdmin, userProjectsIdsByRole.finAdmin]);

  const userAdminProjects = useMemo(() => {
    if (!userProjectsIdsByRole.techAdmin || !projects) return [];
    return projects.filter((project) => userProjectsIdsByRole.techAdmin.includes(project.id));
  }, [userProjectsIdsByRole.techAdmin, projects]);

  const userFinancialAdminProjects = useMemo(() => {
    if (!userProjectsIdsByRole.finAdmin || !projects) return [];
    return projects.filter((project) => userProjectsIdsByRole.finAdmin.includes(project.id));
  }, [userProjectsIdsByRole.finAdmin, projects]);

  const formatDate = useBookingStore((state) => state.formatDate);

  const beginningOfLastMonth = useMemo(() => {
    let date = new Date(); // Current date
    date.setMonth(date.getMonth() - 1); // Subtract one month
    date.setDate(1); // Set to the 1st of the resulting month
    date.setHours(2, 0, 0, 0); // Set time to the start of the day
    return date.toISOString();
  }, []);

  const [startDate, setStartDate] = useState(
    formatDate(beginningOfLastMonth, 'date'),
  );
  const [endDate, setEndDate] = useState(
    formatDate(new Date().toISOString(), 'date'),
  );
  const [projectId, setProjectId] = useState<number | null>(null);
  const [activeProjectsOnly, setActiveProjectsOnly] = useState<boolean>(true);

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      {userAdminProjects?.length || userFinancialAdminProjects?.length ?
        <Tabs variant="enclosed">
          <TabList>
            <Tab>{t('report', { ns: ['labels'] })}</Tab>
            {userFinancialAdminProjects?.length ? (<>
              <Tab data-test-id="report-monthly-tab">{t('monthlyReport', { ns: ['labels'] })}</Tab>
              <Tab data-test-id="report-by-resource-tab">{t('byResource', { ns: ['labels'] })}</Tab>
              <Tab data-test-id="report-project-tab">{t('projectsReport', { ns: ['labels'] })}</Tab>
            </>) : null}
          </TabList>

          <TabPanels>
            <TabPanel>
              <Card px="0px">
                <SearchTableReports
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  projectId={projectId}
                  setProjectId={setProjectId}
                  activeProjectsOnly={activeProjectsOnly}
                  setActiveProjectsOnly={setActiveProjectsOnly}
                />
              </Card>
              <br />
              {userFinancialAdminProjects?.length ? (
                <Card px="0px">
                  <SearchTableTotalsByResource
                    startDate={startDate}
                    endDate={endDate}
                    projectId={projectId}
                    activeProjectsOnly={activeProjectsOnly}
                  />
                </Card>) : null}
            </TabPanel>
            {userFinancialAdminProjects?.length ? (
              <TabPanel>
                <Card px="0px">
                  <SearchTableMonthlyReport />
                </Card>
              </TabPanel>
            ) : null}
            {userFinancialAdminProjects?.length ? (
              <TabPanel>
                <Card px="0px">
                  <SearchTableByResource />
                </Card>
              </TabPanel>
            ) : null}
            {userFinancialAdminProjects?.length ? (
              <TabPanel>
                <Card px="0px">
                  <SearchTableProjectReports />
                </Card>
              </TabPanel>
            ) : null}
          </TabPanels>
        </Tabs>
        : loadingState
          ? <Spinner />
          : <Text>{t('youHaveNoAdminProjects', { ns: ['labels'] })}</Text>
      }
    </Flex>
  );
}
