export const makeArrOfObjectsUniqueByKey =
  <T extends { [key: string]: any }>(arr: T[], uniqueKey: string): T[] => {
    const uniqueObjects = arr.reduce((acc, current) => {
      if (!acc.has(current[uniqueKey])) {
        acc.set(current[uniqueKey], current);
      }
      return acc;
    }, new Map<any, T>());

    return Array.from(uniqueObjects.values());
  }

export const handleFloatInputWithStoringDataFunction = (inputValue: string, handleData: (val: string | number) => void): string => {
  let sanitizedValue = inputValue.replace(/[^0-9.,]/g, "");
  handleData(sanitizedValue);

  if (
    sanitizedValue[sanitizedValue.length - 2] === '.'
    && sanitizedValue[sanitizedValue.length - 1] === '0'
  ) {
    return sanitizedValue;
  }

  const numericValue = parseFloat(sanitizedValue.replace(/,/g, "."));

  if (!isNaN(numericValue) && /\d$/.test(sanitizedValue)) {
    const fixedNumericValue = parseFloat(numericValue.toFixed(2));
    handleData(fixedNumericValue);
  }

  return sanitizedValue;
};
