import {
  Checkbox,
  FormLabel,
  Select,
  SimpleGrid,
  Stack,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import useFormErrorsStore from 'contexts/formErrorsStore';
import { HourFormats, TimeUnits, useProjectStore } from 'contexts/globalStoreProjects';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  vatRate: yup.number().nullable().min(0).max(100, "lessOrEqualHundred").typeError("typeErrorNumber"),
  language: yup.string().nullable(),
  currency: yup.string().nullable(),
  termsOfPayment: yup.string().nullable(),
  mergeBookingsDescriptions: yup.boolean().nullable(),
  rounding: yup.number().min(0).nullable(),
  roundingType: yup.string().nullable(),
  hourFormat: yup.string(),
});

export const ProjectInvoice = ({ viewOnly }: { viewOnly: boolean }) => {
  const textColor = useColorModeValue('black', 'white');

  const singleProjectData = useProjectStore((state: any) => state.project);

  const setTrue = useProjectStore((state) => state.setToTrue);

  const projectLanguages = useProjectStore((state) => state.languages);
  const projectCurrencies = useProjectStore((state) => state.currencies);
  const projectTermsOfPayments = useProjectStore(
    (state) => state.termsOfPayments,
  );
  const projectHourFormats = useProjectStore((state) => state.hourFormats);
  const projectTimeUnits = useProjectStore((state) => state.timeUnits);

  const setProjectCreateObj = useProjectStore((state) => state.setProjectData);
  const setErrors = useFormErrorsStore((state) => state.setErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const deleteErrorsContainingString = useFormErrorsStore(
    (state) => state.deleteErrorsContainingString,
  )

  const handleChange = (field: any, value: any) => {
    setProjectCreateObj({
      [field]: value,
    });
  };

  const [eachFieldStateValidation, setEachFieldStateValidation] = useState({
    vatRate: { valid: true, error: '' },
    language: { valid: true, error: '' },
    currency: { valid: true, error: '' },
    termsOfPayment: { valid: true, error: '' },
    rounding: { valid: true, error: '' },
    roundingType: { valid: true, error: '' },
    hourFormat: { valid: true, error: '' },
  });

  const validateSingleField = useCallback(async (path: string, input: any) => {
    // `reach()` pulls out a child schema so we can test a single path
    const field: any = yup.reach(validationSchema, path);

    // TODO @Polina - please think how we can do without this crazy code, as on load hourFormat is undefined
    if (path === 'hourFormat' && !input) {
      handleChange('hourFormat', 'MINUTES');

      return
    }

    try {
      await field.validate(input);
      setEachFieldStateValidation({
        ...eachFieldStateValidation,
        [path]: { valid: true, error: '' },
      });
    } catch (e: any) {
      setEachFieldStateValidation({
        ...eachFieldStateValidation,
        [path]: { valid: false, error: e.errors.join(', ') },
      });
      return e.errors;
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eachFieldStateValidation, setEachFieldStateValidation]);

  const validateFormData = useCallback(async (formData: any) => {
    const invoiceError = t('invoiceError', { ns: ['errors'] });
    deleteErrorsContainingString(invoiceError);

    try {
      await validationSchema.validate(formData, {
        abortEarly: false,
      });
      setTrue('invoiceValidation', true);

    } catch (error: any) {
      setTrue('invoiceValidation', false);

      // This is to show errors for fields after submit
      // Even if they were not touched
      const newEachFieldStateValidation: any = { ...eachFieldStateValidation };
      if (error.inner) {
        for (const e of error.inner) {
          const fieldErrors = await validateSingleField(e.path, e.value);

          if (fieldErrors) {
            newEachFieldStateValidation[e.path] = { valid: false, error: fieldErrors.join(', ') };
          }
        }
      }
      setEachFieldStateValidation(newEachFieldStateValidation);

      setErrors([...error.errors.map(
        (e: string) => `${invoiceError}: ${t(e, { ns: ['hints'] })}`
      )]);
    }
    // Will work incorrectly if you add validateSingleField and eachFieldStateValidation as dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteErrorsContainingString, setErrors, setTrue]);

  const [debouncedFormData, setDebouncedFormData] = useState({});

  useEffect(() => {
    const debounceValidation = setTimeout(() => {
      setDebouncedFormData(singleProjectData);
    }, 500);

    return () => clearTimeout(debounceValidation);
  }, [singleProjectData]);

  useEffect(() => { validateFormData(debouncedFormData) }, [debouncedFormData, validateFormData]);

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 4 }} gap="20px">
        <Stack direction="column" gap="20px" mb="10">
          <Flex direction="column">
            <InputField
              bgColor={
                eachFieldStateValidation['vatRate'].valid
                  ? 'transparent'
                  : 'red.100'
              }
              color={
                eachFieldStateValidation['vatRate'].valid
                  ? textColor
                  : 'red.500'
              }
              mb="0px"
              id="vatRate"
              placeholder={t('vatRate', { ns: ['labels'] })}
              label={t('vatRate', { ns: ['labels'] }) + '*'}
              value={singleProjectData?.vatRate || 0}
              data-test-id="project-invoice-vat-rate-input"
              disabled={viewOnly}
              onChange={(event: any) => {
                handleChange('vatRate', Number(event.target.value));
                validateSingleField('vatRate', Number(event.target.value));
              }}
            />
            <Text data-test-id="project-invoice-vat-rate-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
              {t(eachFieldStateValidation['vatRate'].error, { ns: ['hints'] })}
            </Text>
          </Flex>
        </Stack>
        <Stack direction="column" gap="20px">
          <Checkbox
            mt="35px"
            defaultChecked={singleProjectData?.reverseCharge || false}
            disabled={viewOnly}
            data-test-id="project-invoice-reverse-charge-checkbox"
            onChange={(event: any) =>
              handleChange('reverseCharge', event.target.checked)
            }
          >
            {t('reverseCharge', { ns: ['labels'] })}
          </Checkbox>
        </Stack>
        <Stack direction="column" gap="20px">
          <Checkbox
            mt="35px"
            mb="30px"
            defaultChecked={singleProjectData?.timesheetOnInvoice || false}
            disabled={viewOnly}
            data-test-id="project-invoice-timesheet-on-invoice-checkbox"
            onChange={(event: any) =>
              handleChange('timesheetOnInvoice', event.target.checked)
            }
          >
            {t('timesheetOnInvoice', { ns: ['labels'] })}
          </Checkbox>
        </Stack>
        <Stack direction="column" gap="20px">
          <Checkbox
            mt="35px"
            mb="30px"
            defaultChecked={singleProjectData?.mergeBookingsDescriptions || false}
            disabled={viewOnly}
            data-test-id="project-invoice-merge-bookings-descriptions-checkbox"
            onChange={(event: any) =>
              handleChange('mergeBookingsDescriptions', event.target.checked)
            }
          >
            {t('mergeBookingsDescriptions', { ns: ['labels'] })}
          </Checkbox>
        </Stack>
      </SimpleGrid>
      {/* Line 1 */}

      {/* Line 2 */}
      <SimpleGrid columns={{ base: 1, md: 4 }} gap="20px" mb="5">
        <Stack direction="column" gap="20px">
          <Flex direction="column">
            <FormLabel
              ms="10px"
              htmlFor="language"
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              {t('language', { ns: ['labels'] }) + '*'}
            </FormLabel>
            <Select
              fontSize="sm"
              id="language"
              variant="main"
              h="44px"
              maxH="44px"
              style={{ color: textColor }}
              placeholder={t('select', { ns: ['labels'] })}
              required
              value={singleProjectData?.language || ''}
              disabled={viewOnly}
              data-test-id="project-invoice-language-select"
              onChange={(event: any) => {
                handleChange('language', event.target.value);
                validateSingleField('language', event.target.value);
              }}
            >
              {projectLanguages.map((language) => (
                <option
                  key={language.value}
                  value={language.value}
                  style={{ color: 'secondaryGray.900' }}
                >
                  {language.label}
                </option>
              ))}
            </Select>
            {eachFieldStateValidation?.['language']?.error && showErrors
              ? <Text data-test-id="project-invoice-language-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                {t(eachFieldStateValidation['language'].error, { ns: ["hints"] })} &nbsp;
              </Text> : null}
          </Flex>
        </Stack>
        <Stack direction="column" gap="20px">
          <Flex direction="column">
            <FormLabel
              ms="10px"
              htmlFor="currency"
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              {t('currency', { ns: ['labels'] }) + '*'}
            </FormLabel>
            <Select
              fontSize="sm"
              id="currency"
              variant="main"
              h="44px"
              maxH="44px"
              required
              style={{ color: textColor }}
              placeholder={t('select', { ns: ['labels'] })}
              value={singleProjectData?.currency || ''}
              data-test-id="project-invoice-currency-select"
              disabled={viewOnly}
              onChange={(event: any) => {
                handleChange('currency', event.target.value);
                validateSingleField('currency', event.target.value);
              }}
            >
              {projectCurrencies.map((currencies) => (
                <option
                  key={currencies.value}
                  value={currencies.value}
                  style={{ color: 'secondaryGray.900' }}
                >
                  {currencies.label}
                </option>
              ))}
            </Select>
            {eachFieldStateValidation?.['currency']?.error && showErrors
              ? <Text data-test-id="project-invoice-currency-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                {t(eachFieldStateValidation['currency'].error, { ns: ["hints"] })} &nbsp;
              </Text> : null}
          </Flex>
        </Stack>
        <Stack direction="column" gap="20px" gridColumn={'span 2'}>
          <Flex direction="column">
            <FormLabel
              ms="10px"
              htmlFor="termsOfPayment"
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              {t('terms_of_payment', { ns: ['labels'] }) + '*'}
            </FormLabel>
            <Select
              fontSize="sm"
              id="termsOfPayment"
              variant="main"
              h="44px"
              maxH="44px"
              placeholder={t('select', { ns: ['labels'] })}
              required
              style={{ color: textColor }}
              value={singleProjectData?.termsOfPayment || ''}
              data-test-id="project-invoice-terms-of-payment-select"
              disabled={viewOnly}
              onChange={(event: any) => {
                handleChange('termsOfPayment', event.target.value);
                validateSingleField('termsOfPayment', event.target.value);
              }}
            >
              {projectTermsOfPayments.map((top) => (
                <option
                  key={top.value}
                  value={top.value}
                  style={{ color: 'secondaryGray.900' }}
                >
                  {t(top.value, { ns: ['labels'] })}
                </option>
              ))}
            </Select>
            {eachFieldStateValidation?.['termsOfPayment']?.error && showErrors
              ? <Text data-test-id="project-invoice-terms-of-payment-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                {t(eachFieldStateValidation['termsOfPayment'].error, { ns: ["hints"] })} &nbsp;
              </Text> : null}
          </Flex>
        </Stack>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 4 }} gap="20px" mt="5px">
        <Stack direction="column" gap="20px">
          <Flex direction="column">
            <FormLabel
              ms="10px"
              mt="5px"
              htmlFor="rounding"
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              {t('rounding', { ns: ['labels'] }) + '*'}
            </FormLabel>
            <Select
              fontSize="sm"
              id="rounding"
              variant="main"
              h="44px"
              maxH="44px"
              required
              style={{
                color: eachFieldStateValidation['rounding'].valid
                  ? textColor
                  : '#E53E3E',
              }}
              value={
                singleProjectData?.rounding !== null
                  ? singleProjectData?.rounding
                  : 0
              }
              data-test-id="project-invoice-rounding-select"
              onChange={(event: any) => {
                handleChange(
                  'rounding',
                  event.target.value.length > 0
                    ? Number(event.target.value)
                    : null,
                );
                validateSingleField(
                  'rounding',
                  event.target.value.length > 0
                    ? Number(event.target.value)
                    : null,
                );
              }}
              disabled={viewOnly}
            >
              <option value={0}>{t('precise', { ns: ['labels'] })}</option>
              <option value={15}>15</option>
              <option value={30}>30</option>
              <option value={60}>60</option>
            </Select>
            <Text data-test-id="project-invoice-rounding-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
              {t(eachFieldStateValidation['rounding'].error, { ns: ["hints"] })} &nbsp;
            </Text>
          </Flex>
        </Stack>
        {singleProjectData?.rounding && singleProjectData.rounding !== 0 ? (
          <Stack direction="column" gap="20px">
            <Flex direction="column">
              <FormLabel
                ms="10px"
                mt="5px"
                htmlFor="roundingType"
                fontSize="sm"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                {t('roundingType', { ns: ['labels'] }) + '*'}
              </FormLabel>
              <Select
                fontSize="sm"
                id="roundingType"
                variant="main"
                h="44px"
                maxH="44px"
                required
                style={{
                  color: eachFieldStateValidation['roundingType'].valid
                    ? textColor
                    : '#E53E3E',
                }}
                value={singleProjectData.roundingType}
                data-test-id="project-invoice-rounding-type-select"
                onChange={(event: any) => {
                  handleChange(
                    'roundingType',
                    event.target.value.length > 0
                      ? event.target.value
                      : null,
                  );
                  validateSingleField(
                    'roundingType',
                    event.target.value.length > 0
                      ? event.target.value
                      : null,
                  );
                }}
              >
                <option value={'up'}>Up</option>
                <option value={'down'}>Down</option>
                <option value={'mercantile'}>Mercantile</option>
              </Select>
              <Text data-test-id="project-invoice-rounding-type-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                {t(eachFieldStateValidation['roundingType'].error, { ns: ["hints"] })} &nbsp;
              </Text>
            </Flex>
          </Stack>
        ) : null}
        <Stack direction="column" gap="20px" mt="5px">
          <InputField
            mb="0px"
            placeholder="Optional"
            label={t('reference', { ns: ['labels'] })}
            value={singleProjectData?.reference || ''}
            disabled={viewOnly}
            data-test-id="project-invoice-reference-input"
            onChange={(event: any) => {
              handleChange('reference', event.target.value.toString());
            }}
          />
        </Stack>
        <Stack direction="row" gap="20px" gridColumn={'span 2'}>
          <Flex direction="column" w={'50%'}>
            <FormLabel
              ms="10px"
              htmlFor="timeUnit"
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              {t('timeUnit', { ns: ['labels'] })}
            </FormLabel>
            <Select
              variant="main"
              fontSize="sm"
              id="timeUnit"
              textColor={textColor}
              h="44px"
              maxH="44px"
              value={singleProjectData?.timeUnit}
              required
              data-test-id="project-invoice-time-unit-select"
              disabled={viewOnly}
              onChange={async (event: any) => {
                if (event.target.value === 'PERSON_DAYS') {
                  handleChange('hourFormat', 'DECIMALS');
                }
                handleChange('timeUnit', event.target.value);
              }}
            >
              {projectTimeUnits?.map((unit: TimeUnits) => {
                return (
                  <option key={unit.value} value={unit.value}>
                    {t(unit.label, { ns: ['labels'] })}
                  </option>
                );
              })}
            </Select>
          </Flex>
          <Flex direction="column" w={'50%'}>
            <FormLabel
              ms="10px"
              htmlFor="hourFormat"
              fontSize="sm"
              fontWeight="bold"
              _hover={{ cursor: 'pointer' }}
            >
              {t('hourFormat', { ns: ['labels'] })}
            </FormLabel>
            <Select
              variant="main"
              fontSize="sm"
              id="hourFormat"
              textColor={textColor}
              h="44px"
              maxH="44px"
              value={singleProjectData?.hourFormat}
              required
              data-test-id="project-invoice-hour-format-select"
              disabled={viewOnly || singleProjectData?.timeUnit === 'PERSON_DAYS'}
              onChange={async (event: any) => {
                handleChange('hourFormat', event.target.value);
                await validateSingleField('hourFormat', event.target.value);
              }}
            >
              {projectHourFormats?.map((format: HourFormats) => {
                return (
                  <option key={format.value} value={format.value}>
                    {t(format.label, { ns: ['labels'] })}
                  </option>
                );
              })}
            </Select>
            <Text data-test-id="project-invoice-hour-format-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
              {showErrors ? t(eachFieldStateValidation['hourFormat']?.error, { ns: ['hints'] }) : null}{' '}
              &nbsp;
            </Text>
          </Flex>
        </Stack>
      </SimpleGrid>
    </>
  );
};
