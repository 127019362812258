import { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { endOfDay, format } from 'date-fns';
import {
  Badge,
  Checkbox,
  IconButton,
  SimpleGrid,
  Spacer,
  Spinner,
  useToast,
  Button,
  Flex,
  Text,
  useColorModeValue,
  Stack,
  Td,
  Tr,
  Input,
  Select
} from '@chakra-ui/react';

import { Select as SelectWithMultipleOption } from 'chakra-react-select';

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
} from '@tanstack/react-table';
import FakturaTable from 'components/tables/FakturaTable';
import { ProjectUser, useProjectStore } from 'contexts/globalStoreProjects';
import useLoaderStore from 'contexts/globalStore';
import { RepeatIcon } from '@chakra-ui/icons';
import { useBookingStore } from 'contexts/globalStoreTimeBookings';
import { useInvoicesStore } from 'contexts/globalStoreInvoices';
import { tableStorage } from 'contexts/tableStorage';
import {
  getMonthRangeFromDateValue,
  toISOWithoutTimezone
} from 'utils/dateHelpers';
import useUserDataRolesStore from 'contexts/authStore';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE_DEFAULT } from 'variables/pagination';
import { useFakturaUsersStore, User } from 'contexts/globalStoreFakturaUsers';
import { ProjectUserRoles } from 'enums/userRoles';

type RowObj = {
  select: string;
  ids: string;
  taskId: number;
  userId: number;
  totalDuration: number;
  clientRate: number;
  totalClientCost: number;
  totalResourceCost: number;
  profit: number;
};

interface Resource {
  value: number;
  label: string;
  name: string;
  email: string;
}

export default function SearchTableCreateInvoice(
  { setNewInvoiceCreatedTrigger }:
    { setNewInvoiceCreatedTrigger: (value: boolean) => void }
) {
  const textColor = useColorModeValue('navy.700', 'white');
  const toast = useToast();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const formatDate = useBookingStore((state) => state.formatDate);

  const isInitDone = useInvoicesStore((state) => state.isInitDone);

  const hasUserRoleInAnyProject = useUserDataRolesStore((state) => state.hasUserRoleInAnyProject);

  const checkIfInvoiceDataIsInProjects = useProjectStore(
    (state) => state.checkIfInvoiceDataIsInProjects
  )

  const [userFinancialAdminProjects, setUserFinancialAdminProjects] = useState([]);
  const fetchProjectUsers = useProjectStore((state) => state.fetchProjectUsers);

  const { fakturaTasks } = useProjectStore(
    (state: any) => ({ fakturaTasks: state.allTasks }),
  );
  const { fakturaInvoiceCreate } = useInvoicesStore(
    (state: any) => ({
      fakturaInvoiceCreate: state.invoiceCreate,
    }),
  );

  const { invoiceCreateMeta } = useInvoicesStore(
    (state: any) => ({
      invoiceCreateMeta: state.invoiceCreateMeta,
    }),
  );

  const { fakturaInvoiceCurrency } = useInvoicesStore(
    (state: any) => ({
      fakturaInvoiceCurrency: state.invoiceCurrency,
    }),
  );

  const { fakturaTotalClientCost } = useInvoicesStore(
    (state: any) => ({
      fakturaTotalClientCost: state.totalClientCost,
    }),
  );
  const { fakturaTotalDurationSum } = useInvoicesStore(
    (state: any) => ({
      fakturaTotalDurationSum: state.totalDurationSum,
    }),
  );
  const { fakturaTotalResourceCostSum } = useInvoicesStore(
    (state: any) => ({
      fakturaTotalResourceCostSum: state.totalResourceCostSum,
    }),
  );
  const { fakturaTotalProfitSum } = useInvoicesStore(
    (state: any) => ({
      fakturaTotalProfitSum: state.totalProfitSum,
    }),
  );

  const beginningOfCurrentMonth = () => {
    let date = new Date(); // Current date
    date.setDate(1); // Set to the 1st of the current month
    date.setHours(0, 0, 0, 0); // Set time to the start of the day
    return toISOWithoutTimezone(date);
  };

  const endOfCurrentMonth = () => {
    let date = new Date(); // Current date
    date.setMonth(date.getMonth() + 1); // Add one month
    date.setDate(0); // Set to the last day of the previous month
    date.setHours(23, 59, 59, 999); // Set time to the end of the day
    return date.toISOString();
  };

  const fetchInvoiceCreate = useInvoicesStore(
    (state) => state.fetchInvoiceCreate,
  );
  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);
  const resetInvoiceList = useInvoicesStore(
    (state) => state.resetInvoiceCreate,
  );
  const resetInvoiceTotals = useInvoicesStore((state) => state.resetTotals);
  const getTimesheet = useInvoicesStore((state) => state.getTimesheet);
  function convertMinutesToHoursAndMinutes(minutes: number) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  }
  const [startDate, setStartDate] = useState(
    formatDate(beginningOfCurrentMonth(), 'date'),
  );

  const [endDate, setEndDate] = useState(
    formatDate(endOfCurrentMonth(), 'date'),
  );

  const fetchUsers = useFakturaUsersStore((state) => state.fetchUsers);

  const [projectId, setProjectId] = useState<number | ''>('');
  const [isProjectInitiallySelected, setIsProjectInitiallySelected] = useState(false);
  const [projectResources, setProjectResources] = useState<Resource[]>([]);
  const [selectedResources, setSelectedResources] = useState<Resource[]>([]);

  const handleProjectIdChange = useCallback((newProjectId: number) => {
    setProjectId(newProjectId);

    fetchUsers().then(fakturaUsers =>
      fetchProjectUsers(newProjectId).then((projectUsers) => { // For the select of project users (resources)
        const filteredResources = projectUsers.filter((pu: ProjectUser) => {
          return pu.role === ProjectUserRoles.USER
            && fakturaUsers.find((user: User) => user.userId === pu.userId);
        });

        const resourcesToSet = filteredResources.map((pu: ProjectUser) => {
          const user = fakturaUsers.find((user: User) => user.userId === pu.userId);
          if (user) {

          }
          return {
            value: pu.id,
            label: `${user?.name} (${user?.email})`,
            name: user?.name,
            email: user?.email
          };
        }).sort((a, b) => a.email.localeCompare(b.email));

        setProjectResources(resourcesToSet);
        setSelectedResources(resourcesToSet); // Select all first

        if (!isProjectInitiallySelected) {
          setIsProjectInitiallySelected(true);
        }
      }));
  }, [fetchUsers, fetchProjectUsers, isProjectInitiallySelected]);

  const handleResourcesChange = useCallback((e: any) => {
    setSelectedResources(e);
  }, []);

  const [activeProjectsOnly, setActiveProjectsOnly] = useState<boolean>(true);
  // const handleActiveProjectsToggle = (value: boolean) => {
  //   const newActiveProjectsOnlyValue = value;
  //   setActiveProjectsOnly(newActiveProjectsOnlyValue);
  // };

  const [data, setData] = useState<any[]>(() => []);

  const [taskData, setTaskData] = useState<any[]>(() => []);
  const [currencyData, setCurrencyData] = useState<any[]>(() => []);
  const currency = useMemo(() => currencyData[0]?.currency, [currencyData]);

  const handleDateChange = (value: string, type: 'start' | 'end') => {
    const startDay = type === 'start' ? value : startDate;
    const endDay = type === 'end'
      ? endOfDay(new Date(value)).toISOString()
      : getMonthRangeFromDateValue(startDay).endDay.toISOString();

    setStartDate(startDay);
    setEndDate(endDay);
    setData(fakturaInvoiceCreate);
    setCurrencyData(fakturaInvoiceCurrency);
  };

  useEffect(() => {
    setLoadingState(true);
    let isCurrent = true;

    if (isCurrent) {
      setData(fakturaInvoiceCreate);
      setTaskData(fakturaTasks)
      setCurrencyData(fakturaInvoiceCurrency);
    }
    setLoadingState(false);
    return () => {
      isCurrent = false;
    };
  }, [fakturaInvoiceCreate, fakturaInvoiceCurrency, fakturaTasks, setLoadingState]);

  const [globalFilter, setGlobalFilter] = useState('');

  const columnHelper = createColumnHelper<RowObj>();

  const columns = [
    columnHelper.accessor('select', {
      id: 'select',
      cell: ({ row }: any) => {
        const id = row.original.id;

        return (
          <>
            <Checkbox
              id="select"
              isChecked={row.getIsSelected() || false}
              onChange={row.getToggleSelectedHandler()}
              colorScheme="brandScheme"
              data-test-id={`invoices-create-table-checkbox-selected-${id}`} />
          </>
        );
      },
    }),
    columnHelper.accessor('taskId', {
      id: 'taskId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="invoices-create-table-header-task"
        >
          {t('taskHeader', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.ids;
        const taskId = info.row.original.taskId;
        const task = taskData.find((task) => Number(task.id) === Number(taskId));
        if (task) {
          return (
            <Badge
              colorScheme="purple"
              fontSize="sm"
              fontWeight="500"
              size="sm"
              key={taskId}
              data-test-id={`invoices-create-table-cell-task-${id}`}
            >
              {task.name}
            </Badge>
          );
        }
      },
    }),
    columnHelper.accessor('totalDuration', {
      id: 'totalDuration',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="invoices-create-table-header-total-amount"
        >
          {t('totalAmountHeader', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.ids;
        const totalMinutes = info.getValue();
        const formattedDuration = convertMinutesToHoursAndMinutes(totalMinutes);

        return (

          <Text color={textColor} fontSize="md" fontWeight="500" data-test-id={`invoices-create-table-cell-total-amount-${id}`}>
            {formattedDuration}
          </Text>
        );
      },
    }),

    columnHelper.accessor('clientRate', {
      id: 'clientRate',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="invoices-create-table-header-client-rate"
        >
          {t('clientRate', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.ids;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500" data-test-id={`invoices-create-table-cell-client-rate-${id}`}>
            {info.getValue()} {currency}/h
          </Text>
        );
      },
    }),

    columnHelper.accessor('totalClientCost', {
      id: 'totalClientCost',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="invoices-create-table-header-total-client-cost"
        >
          {t('total_client_cost', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.ids;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500" data-test-id={`invoices-create-table-cell-total-client-cost-${id}`}>
            {info.getValue()} {currency}
          </Text>
        );
      },
    }),

    columnHelper.accessor('totalResourceCost', {
      id: 'totalResourceCost',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="invoices-create-table-header-total-resource-cost"
        >
          {t('total_resource_cost', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.ids;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500" data-test-id={`invoices-create-table-cell-total-resource-cost-${id}`}>
            {info.getValue()} {currency}
          </Text>
        );
      },
    }),
    columnHelper.accessor('profit', {
      id: 'profit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="invoices-create-table-header-profit"
        >
          {t('profit', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.ids;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500" data-test-id={`invoices-create-table-cell-profit-${id}`}>
            {info.getValue()} {currency}
          </Text>
        );
      },
    }),
  ];

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: PAGE_INDEX_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      rowSelection,
      sorting
    },
    pageCount: invoiceCreateMeta?.totalPages || 0,
    rowCount: invoiceCreateMeta?.totalItems || 0,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  const tableName = useMemo(() => 'invoicesCreate', []);

  const chosenBookingIds = useMemo(() =>
    table.getSelectedRowModel().rows.map((row) => {
      const ids = row.original.ids;
      const idsToNum = Number(ids);

      if (isNaN(idsToNum)) {
        return ids.split(',').map(Number);
      }
      return [idsToNum];
    }).flat(),
    // Don't change table.getSelectedRowModel().rows to just table in dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.getSelectedRowModel().rows]);

  const [areFiltersFromLocalStorageLoaded, setAreFiltersFromLocalStorageLoaded] = useState(false);
  const areAllProjectResourcesSelected = useMemo(
    () => !projectResources?.length || selectedResources?.length === projectResources?.length,
    [projectResources, selectedResources]);

  useEffect(() => {
    if (areFiltersFromLocalStorageLoaded) return;

    const {
      globalFilter: newGlobalFilter,
      startDate: newStartDate,
      endDate: newEndDate,
      projectId: newProjectId,
      activeProjectsOnly: newActiveProjectsOnly
    } = tableStorage.fetch(tableName);

    setGlobalFilter(newGlobalFilter);
    setActiveProjectsOnly(newActiveProjectsOnly);

    const selectedDateValue = newStartDate ?? format(new Date(), 'yyyy-MM-dd');

    if (!newEndDate) {
      const { startDay, endDay } = getMonthRangeFromDateValue(selectedDateValue);
      setStartDate(startDay.toISOString());
      setEndDate(endDay.toISOString());
    } else {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }

    if (newProjectId) {
      handleProjectIdChange(newProjectId); // Not just setProjectId(newProjectId) because we need to fetch project users
    }

    setAreFiltersFromLocalStorageLoaded(true);

  }, [areFiltersFromLocalStorageLoaded, handleProjectIdChange, loadingState, projectId, tableName, userFinancialAdminProjects])

  useEffect(() => {
    if (!projectId && userFinancialAdminProjects?.length) {
      handleProjectIdChange(userFinancialAdminProjects[0]?.id);
    }
  }, [handleProjectIdChange, projectId, userFinancialAdminProjects]);

  useEffect(() => {
    tableStorage.save(tableName, {
      globalFilter,
      startDate,
      endDate,
      projectId,
      activeProjectsOnly
    })
  }, [globalFilter, startDate, endDate, projectId, tableName, activeProjectsOnly])

  const refreshData = useCallback(() => {
    if (!isProjectInitiallySelected) return;

    setIsInvoicesDataLoadingLocal(true);
    setLoadingState(true);
    table.resetRowSelection();

    fetchInvoiceCreate(
      startDate,
      endDate,
      projectId,
      'data',
      selectedResources?.map((resource) => resource.value),
      [],
      activeProjectsOnly,
      pagination.pageIndex + 1,
      pagination.pageSize,
      sorting[0]?.id || null,
      sorting[0]?.desc ? 'desc' : 'asc'
    )
      .then((res) => {
        setLoadingState(false);
        if (!res || res.type !== 'success') {
          toast({
            title: t('error', { ns: ['labels'] }),
            description: res.message,
            status: res.type,
          });
        }
        setIsInvoicesDataLoadingLocal(false);
      })
      .catch((error) => {
        setLoadingState(false);
        console.error('Error fetching Faktura Invoices:', error);
        toast({
          title: t('error', { ns: ['labels'] }),
          description: error.message,
          status: error.type,
        });
        setIsInvoicesDataLoadingLocal(false);
      });
  }, [isProjectInitiallySelected, setLoadingState, table, fetchInvoiceCreate, startDate, endDate, projectId, selectedResources, activeProjectsOnly, pagination.pageIndex, pagination.pageSize, sorting, toast]);

  useEffect(() => {
    let isCurrent = true;
    setLoadingState(true);
    if (isCurrent && isInitDone) {
      if (startDate && endDate && projectId) {
        refreshData();
      } else {
        resetInvoiceList();
        resetInvoiceTotals();
      }
    }
    table.resetRowSelection();
    setLoadingState(false);

    return () => {
      isCurrent = false;
    };
  }, [startDate, endDate, projectId, isInitDone, refreshData, resetInvoiceList, resetInvoiceTotals, setLoadingState, table]);

  const handleTimesheetButtonClick = useCallback(() => {
    setLoadingState(true);
    getTimesheet(startDate,
      endDate,
      projectId,
      'timesheet',
      activeProjectsOnly,
      selectedResources.map((resource) => resource.value),
      chosenBookingIds || [])
      .then(() => {
        setLoadingState(false);
      })
      .catch((error) => {
        setLoadingState(false);
        console.error('Error fetching Timesheet Invoices:', error);
      });
  }, [setLoadingState, getTimesheet, startDate, endDate, projectId, activeProjectsOnly, selectedResources, chosenBookingIds]);

  const userData = useUserDataRolesStore((state) => state.userData);
  const userProjectsIdsByRole = useUserDataRolesStore((state) => state.userProjectsIdsByRole);

  const projects = useProjectStore((state) => state.projects);
  const areProjectsLoading = useProjectStore((state) => state.areProjectsLoading);

  const [areProjectsLoadingLocal, setAreProjectsLoadingLocal] = useState(false);
  const [isInvoicesDataLoadingLocal, setIsInvoicesDataLoadingLocal] = useState(false);

  useEffect(() => {
    const fetchAndSetFinancialAdminProjects = async () => {
      if (!areProjectsLoading) {
        setAreProjectsLoadingLocal(true);

        const userFinAdminProjects = projects
          .filter((project) => userProjectsIdsByRole.finAdmin.includes(project.id));

        const projectIdsWithInvoiceData = await
          checkIfInvoiceDataIsInProjects(userFinAdminProjects.map(p => p.id))

        const filteredProjects = userFinAdminProjects.filter(
          (project) => projectIdsWithInvoiceData.includes(project.id)
        );
        setUserFinancialAdminProjects(filteredProjects);

        setAreProjectsLoadingLocal(false);
      }
    };

    fetchAndSetFinancialAdminProjects();
  }, [userData, checkIfInvoiceDataIsInProjects, areProjectsLoading, projects, userProjectsIdsByRole.finAdmin]);

  const invoicesDataExist = useMemo(() =>
    Boolean(table?.getRowModel()?.rows?.length),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table?.getRowModel()]);

  return (
    <Flex direction="column" w="100%" overflowX="hidden">
      <Flex
        flexDirection={{ sm: 'column', lg: 'row' }}
        align={{ sm: 'flex-start', lg: 'center' }}
        justify="flex-start"
        px="22px"
        mb="10px"
        minWidth="max-content"
      >
        {userFinancialAdminProjects?.length ? (
          <SimpleGrid columns={{ base: 1, xl: 3 }} gap="5px" maxWidth={'100%'} mb="20px">
            <Stack direction="column" gap="20px"
              w='75%'
            >
              <Flex
                flexDirection={{ sm: 'column', lg: 'row' }}
              >
                <Stack direction="column" gap="20px" width="200px">
                  <Input
                    type="date"
                    size="md"
                    // label={t('dateFrom', { ns: ['labels'] })}
                    color={textColor}
                    value={format(new Date(startDate), 'yyyy-MM-dd')}
                    data-test-id={`invoice-create-table-filter-startDate`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDateChange(e.target.value, 'start')}
                  />
                </Stack>
                <Stack direction="column" gap="20px" width="200px">
                  <Input
                    type="date"
                    size="md"
                    // label={t('dateTill', { ns: ['labels'] })}
                    ml={{ sm: 0, lg: 5 }}
                    color={textColor}
                    value={format(new Date(endDate), 'yyyy-MM-dd')}
                    data-test-id={`invoice-create-table-filter-endDate`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDateChange(e.target.value, 'end')}
                  />
                </Stack>
                <Stack direction="column" ml={{ sm: 0, lg: 10 }}>
                  {/* <FormLabel
                ms="10px"
                mb="0"
                fontSize="sm"
                fontWeight="bold"
                _hover={{ cursor: 'pointer' }}
              >
                {t('project', { ns: ['labels'] }) + '*'}
              </FormLabel> */}
                  <Select
                    w="100%"
                    value={projectId}
                    data-test-id={`invoice-create-table-filter-project`}
                    onChange={e => handleProjectIdChange(Number(e.target.value))}
                  >
                    {[...userFinancialAdminProjects]?.sort(
                      (a, b) => a.title.localeCompare(b.title)
                    ).map((project: any) => (
                      <option key={project.id} value={project.id}>
                        {project.title}
                      </option>
                    ))}
                  </Select>
                </Stack>
                {/* Only display Spacer on larger screens */}
                <Spacer display={{ sm: 'none', lg: 'block' }} />

                {loadingState ? (
                  <Spinner />
                ) : (
                  <Stack direction="column" gap="20px" justifyContent={'center'}>

                    <IconButton
                      aria-label="Reload"
                      variant="brand"
                      icon={<RepeatIcon />}
                      data-test-id={`invoice-create-table-refresh-button`}
                      onClick={refreshData}
                    >
                      Reload
                    </IconButton>
                  </Stack>
                )}
              </Flex>
              <Flex
                flexDirection={{ sm: 'column', lg: 'row' }}
                alignItems={{ sm: 'flex-start', lg: 'center' }}
              >
                <SelectWithMultipleOption
                  value={selectedResources}
                  data-test-id={`invoice-create-table-filter-resources`}
                  isMulti
                  placeholder={t('selectResources', { ns: ['labels'] })}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  getOptionLabel={(resource) => `${resource.name} (${resource.email})`}
                  options={projectResources}
                  chakraStyles={{
                    control: (provided) => ({
                      ...provided,
                      width: { sm: '100%', lg: 420 },
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      background: 'transparent',
                      p: 0,
                      w: "30px",
                    }),
                  }}
                  onChange={handleResourcesChange}
                />
                <Button
                  ml={{ sm: '0', lg: 5 }}
                  variant="brand"
                  isDisabled={areAllProjectResourcesSelected}
                  data-test-id={`invoice-create-table-select-all-resources-button`}
                  w={{ sm: '100%', lg: '460px' }}
                  onClick={() => setSelectedResources([...projectResources])}
                >
                  {areAllProjectResourcesSelected
                    ? t('allResourcesSelected', { ns: ['labels'] })
                    : t('selectAllResources', { ns: ['labels'] })}
                </Button>
              </Flex>
            </Stack>
          </SimpleGrid>
        ) : areProjectsLoading || areProjectsLoadingLocal ? <Spinner /> : null}

        {/* <Stack direction="column" gap="20px" justifyContent={'center'}> */}
        {/* <Checkbox
          // isChecked={activeProjectsOnly}
          // onChange={(event: any) => {
          //   handleActiveProjectsToggle(event.target.checked);
          // }}
          >
            {t("onlyActiveProjects", { ns: ["labels"] })}
          </Checkbox> */}
        {/* </Stack> */}
      </Flex>
      <Flex
        align={{ sm: 'flex-start', lg: 'flex-start' }}
        justify={{ sm: 'flex-start', lg: 'flex-start' }}
        w="100%"
        px="22px"
        mb="36px"
        minWidth="max-content"
        alignItems="center"
        gap="4"
      >
        <SimpleGrid columns={{ base: 1, xl: 3 }} gap="5px">
          {hasUserRoleInAnyProject.finAdmin ? (
            <Stack direction="column" gap="20px">
              <Button
                cursor="pointer"
                variant="brand"
                isDisabled={data.length === 0}
                data-test-id={`invoice-create-table-create-button`}
                onClick={() => {
                  setLoadingState(true);
                  fetchInvoiceCreate(
                    startDate,
                    endDate,
                    projectId,
                    'create',
                    selectedResources.map((resource) => resource.value),
                    chosenBookingIds || [],
                    activeProjectsOnly
                  )
                    .then(() => {
                      refreshData();
                      table.resetRowSelection();
                      setLoadingState(false);
                      setNewInvoiceCreatedTrigger(true);
                    })
                    .catch((error) => {
                      setLoadingState(false);
                      console.error('Error fetching Creating Invoices:', error);
                    });
                }}
              >
                {t('create', { ns: ['actions'] })}
              </Button>
            </Stack>
          ) : null}

          <Stack direction="column" gap="20px">
            <Button
              cursor="pointer"
              variant="brand"
              isDisabled={data.length === 0}
              data-test-id={`invoice-create-table-preview-button`}
              onClick={() => {
                setLoadingState(true);
                fetchInvoiceCreate(
                  startDate,
                  endDate,
                  projectId,
                  'preview',
                  selectedResources.map((resource) => resource.value),
                  chosenBookingIds || [],
                  activeProjectsOnly
                )
                  .then(() => {
                    setLoadingState(false);
                  })
                  .catch((error) => {
                    setLoadingState(false);
                    console.error('Error fetching Preview Invoices:', error);
                  });
              }}
            >
              {t('preview', { ns: ['actions'] })}
            </Button>
          </Stack>
          <Stack direction="column" gap="20px">
            <Button
              cursor="pointer"
              isDisabled={data.length === 0}
              variant="brand"
              data-test-id={`invoice-create-table-timesheet-button`}
              onClick={() => handleTimesheetButtonClick()}
            >
              {t('timesheetPdf', { ns: ['actions'] })} PDF
            </Button>
          </Stack>
        </SimpleGrid>
      </Flex>

      {invoicesDataExist ? (<FakturaTable
        table={table}
        additionalBottomRows={
          <Tr>
            <Td></Td>
            <Td>{t("totalsProvisionals", { ns: ["labels"] })}</Td>
            <Td>
              {convertMinutesToHoursAndMinutes(fakturaTotalDurationSum)}
            </Td>
            <Td></Td>
            <Td>
              {fakturaTotalClientCost} {currency}
            </Td>
            <Td>
              {fakturaTotalResourceCostSum} {currency}
            </Td>
            <Td>
              {fakturaTotalProfitSum} {currency}
            </Td>
          </Tr>
        }
        loadingState={loadingState}
        pagination={pagination}
        setPagination={setPagination}
        dataTestId={`invoice-create-table`}
      />) : loadingState || isInvoicesDataLoadingLocal ? <Spinner /> : null
      }
    </Flex >
  );
}
